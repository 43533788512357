import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import getImageServer from '../../../components/global/getImageServer'
import LoadingPage from '../../../components/global/LoadingPage'
import Table from '../../../components/global/table/Table'
import config from '../../../config/config'
import UserContext from '../../../contexts/UserContext'
import { catchErr } from '../../../error/catchErr'
import { SuccesSwal } from '../../../swal/swal'

const Game = () => {
    const { token } = useContext(UserContext)

    const [gamesListData, setGamesListData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const tableHeader = ['#', "Nom de l'équipe", 'Logo', '', '']

    useEffect(() => {
        if (isLoading && gamesListData.length === 0) {
            Axios.get(`${config.backendApi}/api/get-all/games?order[id]=asc`, config.apiHeader())
                .then((res) => {
                    const games = res.data['hydra:member'].map((game) => {
                        const gameData = [
                            game.id,
                            game.gameName,
                            game.gameLogo.mediaName !== null
                                ? ['Image', getImageServer(game.gameLogo.mediaName)]
                                : 'n/a',
                            ['Modifier', `/admin/jeu/modifier/${game.id}`],
                            'Supprimer',
                        ]

                        return gameData
                    })

                    setGamesListData(games)
                    setIsLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [gamesListData.length, isLoading])

    if (isLoading) return <LoadingPage />

    const deleteById = (id) => {
        Axios.delete(`${config.backendApi}/api/games/${id}`, config.apiHeader(token))
            .then((res) => {
                SuccesSwal('La suppression de ce jeu est réussi ', 'refresh')
            })
            .catch((err) => catchErr(err.response))
    }

    return (
        <div className="content">
            <div className="action">
                <Link to="/admin/jeu/creer" className="btn btn-success">
                    Créer un jeu
                </Link>
            </div>
            {gamesListData.length > 0 && (
                <Table dataList={gamesListData} tableHeader={tableHeader} deleteById={deleteById} />
            )}
        </div>
    )
}

export default Game
