import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import getImageServer from '../components/global/getImageServer'
import GlobalBanner from '../components/global/GlobalBanner'
import LoadingPage from '../components/global/LoadingPage'
import MainContainer from '../components/global/MainContainer'
import Game from '../components/teams/Game'
import config from '../config/config'
import { catchErr } from '../error/catchErr'

const Team = () => {
    const [gameList, setGameList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [accordionContent, setAccordionContent] = useState([])

    const isResponsiveTablet = window.innerWidth < 450
    const isResponsivePhone = window.innerWidth < 1150

    useEffect(() => {
        if (isLoading && gameList.length === 0) {
            Axios.get(`${config.backendApi}/api/get-all/games?order[id]=asc`, config.apiHeader())
                .then((res) => {
                    setGameList(res.data['hydra:member'])
                    setIsLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [gameList.length, isLoading])

    if (isLoading) return <LoadingPage />

    const jeuResponsive = () => {
        if (isResponsivePhone) {
            return {
                justifyContent: 'center',
                border: 'none',
                listStyleType: 'none',
                paddingLeft: '0',
                display: 'flex',
                flexDirection: 'column',
            }
        } else if (isResponsiveTablet) {
            return {
                justifyContent: 'center',
                border: 'none',
                listStyleType: 'none',
                paddingLeft: '0',
                display: 'flex',
                flexDirection: 'row',
            }
        } else {
            return {
                justifyContent: 'center',
                border: 'none',
                listStyleType: 'none',
                paddingLeft: '0',
                display: 'flex',
                flexDirection: 'row',
            }
        }
    }

    const handleClickTeam = (index) => {
        accordionContent[index].animate(
            [{ transform: 'translateY(-50px)', opacity: 0 }, { transform: 'translateY(0)' }],
            1000
        )

        if (accordionContent[index].style.display === 'block') {
            accordionContent[index].style.display = 'none'
            return
        }

        accordionContent.forEach((ref) => {
            ref.style.display = 'none'
        })
        accordionContent[index].style.display = 'block'
    }

    return (
        <>
            <GlobalBanner title="teams" subTitle="Nos équipes" />
            <section className="content sbc">
                <MainContainer>
                    <div className="all-recruitments-team d-flex">
                        <ul id="tab-team" className="nav" role="tablist" style={jeuResponsive()}>
                            {gameList.length > 0 &&
                                gameList.map((game, index) => (
                                    <li key={game.id} className="nav-item">
                                        <a
                                            href={`#section${game.id}`}
                                            className="nav-link"
                                            data-toggle="tab"
                                            role="tab"
                                        >
                                            <div className="hexagon" onClick={() => handleClickTeam(index)}>
                                                <div className="hexagon small-back">
                                                    <img
                                                        src={getImageServer(game.gameLogo.mediaName)}
                                                        alt=""
                                                        style={{
                                                            borderRadius: '50%',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div className="recruitments-team d-flex">
                        <div id="tab-team-content" className="tabContent">
                            {gameList.map((game, index) => (
                                <Game
                                    key={game.id}
                                    game={game}
                                    index={index}
                                    accordionContent={accordionContent}
                                    setAccordionContent={setAccordionContent}
                                    isResponsiveTablet={isResponsiveTablet}
                                    isResponsivePhone={isResponsivePhone}
                                />
                            ))}
                        </div>
                    </div>
                </MainContainer>
            </section>
        </>
    )
}

export default Team
