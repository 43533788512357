import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { createRef, useState } from 'react'
import TwitchStream from '../components/twitch/TwitchStream'

// todo : refacto this component
const Twitch = () => {
    const [twitchData, setTwitchData] = useState([
        {
            channel: 'teamdivergentes',
            isShowed: true,
            id: 'teamdivergentes',
            theme: 'dark',
            muted: true,
            ref: createRef(),
            style: {
                width: '80%',
                height: '700px',
                left: 0,
                transform: 'rotateY(0deg)',
                display: 'flex',
            },
        },
        {
            channel: 'teamdivergentes2',
            isShowed: false,
            id: 'teamdivergentes2',
            theme: 'dark',
            muted: true,
            style: {
                width: '80%',
                height: '700px',
                left: window.innerWidth + 'px',
                transform: 'rotateY(90deg)',
                display: 'none',
            },
        },
    ])

    const handleRightSwap = () => {
        const currentIndex = twitchData.findIndex((element) => element.isShowed === true)
        const nextIndex = (currentIndex + 1) % twitchData.length

        twitchData[currentIndex].style = {
            ...twitchData[currentIndex].style,
            left: -Math.floor(twitchData[currentIndex].ref.current.clientWidth / 2) + 'px',
            transform: 'rotateY(-90deg)',
        }

        twitchData[nextIndex].isShowed = true

        setTwitchData(twitchData)

        twitchData[nextIndex].style = {
            ...twitchData[nextIndex].style,
            left: '0px',
            transform: 'rotateY(0deg)',
            display: 'flex',
        }

        twitchData[currentIndex].style = {
            ...twitchData[currentIndex].style,
            display: 'none',
        }

        setTimeout(() => {
            twitchData[currentIndex].isShowed = false

            setTwitchData(twitchData)
        }, 2000)
    }

    return (
        <div className="twitchContainer">
            {twitchData.map((data) => {
                return (
                    <TwitchStream
                        key={data.id}
                        id={data.id}
                        channel={data.channel}
                        style={data.style}
                        theme={data.theme}
                        muted={data.muted}
                        ref={data.ref}
                        onVideoPause={data.onVideoPause}
                    />
                )
            })}

            <div className="btnSwapRight" onClick={handleRightSwap}>
                <FontAwesomeIcon className="btnChevron" icon={['fas', 'chevron-right']} />
            </div>
        </div>
    )
}

export default Twitch
