import Axios from 'axios'
import JwtDecode from 'jwt-decode'
import { useContext, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { images } from '../../assets/images/images'
import config from '../../config/config'
import UserContext from '../../contexts/UserContext'
import { catchErr } from '../../error/catchErr'

const LoginAdmin = () => {
    const user = useContext(UserContext)

    const navigate = useNavigate()

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const handleConnexion = () => {
        const data = {
            username,
            password,
        }

        Axios.post(`${config.backendApi}/api/login_check`, data, config.apiHeader())
            .then((res) => {
                let { token } = res.data
                const tokenData = JwtDecode(token)

                token = `Bearer ${token}`

                localStorage.setItem('token', token)
                user.token = token
                user.roles = tokenData.roles
                user.isAuth = true

                navigate('/admin/dashboard')
            })
            .catch((err) => catchErr(err.response))
    }

    const handlePress = (e) => {
        const { charCode } = e
        const isNotValidate = username && password

        if (charCode === 13 && !isNotValidate) {
            handleConnexion()
        }
    }

    if (user.isAuth) return <Navigate to="/admin/dashboard" />

    return (
        <div id="container-login-form">
            <img className="logo" src={images.logo_teamdivergente_for_hexagon} alt="logo divergente" />
            <form>
                <div className="fieldLoginAdmin">
                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        className="form-control stateLoginForm"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        onKeyPress={handlePress}
                    />
                </div>
                <div className="fieldLoginAdmin">
                    <label htmlFor="password">Mot de passe</label>
                    <input
                        type="password"
                        className="form-control stateLoginForm"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyPress={handlePress}
                    />
                </div>
                <button
                    type="button"
                    className="btnPrimary"
                    onClick={handleConnexion}
                    disabled={username === '' || password === ''}
                >
                    Se connecter
                </button>
            </form>
        </div>
    )
}

export default LoginAdmin
