import React from 'react'

const LoadingPage = () => {
    return (
        <div className="loadingPageContainer">
            <div className="loading">
                <div className="loading__wrapper">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoadingPage
