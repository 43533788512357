import Axios from 'axios'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import getImageServer from '../../../components/global/getImageServer'
import LoadingPage from '../../../components/global/LoadingPage'
import Table from '../../../components/global/table/Table'
import config from '../../../config/config'
import UserContext from '../../../contexts/UserContext'
import { catchErr } from '../../../error/catchErr'
import { SuccesSwal } from '../../../swal/swal'

const News = () => {
    const { token } = useContext(UserContext)

    const [isLoading, setIsLoading] = useState(true)
    const [newsListData, setNewsListData] = useState([])
    const tableHeader = ['#', 'Titre', 'Contenu', 'Image', 'Date de création', 'Catégorie', 'Auteur', '', '']

    useEffect(() => {
        if (isLoading && newsListData.length === 0) {
            Axios.get(`${config.backendApi}/api/get-all/news?order[id]=asc`, config.apiHeader())
                .then((res) => {
                    const newsList = res.data['hydra:member'].map((news) => [
                        news.id,
                        news.title,
                        news.content,
                        news.imageName !== null ? ['Image', getImageServer(news.image.mediaName)] : 'n/a',
                        moment(news.createdAt).format('DD/MM/YYYY'),
                        news.category.name,
                        news.author?.username || '',
                        ['Modifier', `/admin/article/modifier/${news.id}`],
                        'Supprimer',
                    ])

                    setNewsListData(newsList)
                    setIsLoading(false)
                })
                .catch((err) => catchErr(err.response))
        } else {
            setIsLoading(false)
        }
    }, [isLoading, newsListData.length])

    if (isLoading) return <LoadingPage />

    const deleteById = (id) => {
        Axios.delete(`${config.backendApi}/api/news/${id}`, config.apiHeader(token))
            .then(() => {
                SuccesSwal('La suppression de cette article est réussi', 'refresh')
            })
            .catch((err) => catchErr(err.response))
    }

    return (
        <div className="content">
            <div className="action">
                <Link to="/admin/article/creer" className="btn btn-success">
                    Créer un article
                </Link>
            </div>

            {newsListData.length > 0 && (
                <Table dataList={newsListData} tableHeader={tableHeader} deleteById={deleteById} />
            )}
        </div>
    )
}

export default News
