import Axios from 'axios'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LoadingPage from '../../../components/global/LoadingPage'
import Table from '../../../components/global/table/Table'
import config from '../../../config/config'
import UserContext from '../../../contexts/UserContext'
import { catchErr } from '../../../error/catchErr'
import { translateRole } from '../../../models/roles'
import { SuccesSwal } from '../../../swal/swal'

const User = () => {
    const { token } = useContext(UserContext)

    const [usersListData, setUsersListData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const tableHeader = ['#', "Nom d'utilisateur", 'Roles', 'Email', 'Password', 'Date', '', '']

    useEffect(() => {
        if (isLoading && usersListData.length === 0) {
            Axios.get(`${config.backendApi}/api/get-all/users?order[id]=asc`, config.apiHeader())
                .then((res) => {
                    const users = res.data['hydra:member'].map((user) => [
                        user.id,
                        user.username,
                        translateRole(user.roles[0]),
                        user.email,
                        user.password,
                        moment(user.createdAt).format('DD/MM/YYYY'),
                        ['Modifier', `/admin/utilisateur/modifier/${user.id}`],
                        'Supprimer',
                    ])

                    setUsersListData(users)
                    setIsLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [isLoading, usersListData.length])

    if (isLoading) return <LoadingPage />

    const deleteById = (id) => {
        Axios.delete(`${config.backendApi}/api/users/${id}`, config.apiHeader(token))
            .then(() => {
                SuccesSwal('La suppression de cette utilisateur est réussi', 'refresh')
            })
            .catch((err) => catchErr(err.response))
    }

    return (
        <div className="content">
            <div className="action">
                <Link to="/admin/utilisateur/creer" className="btn btn-success">
                    Créer un utilisateur
                </Link>
            </div>
            {usersListData.length > 0 && (
                <Table dataList={usersListData} tableHeader={tableHeader} deleteById={deleteById} />
            )}
        </div>
    )
}

export default User
