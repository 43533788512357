import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import UserContext from '../../../../contexts/UserContext'
import AdminHeader from './partials/AdminHeader'
import VisitorHeader from './partials/VisitorHeader'

const Header = ({ scrollY }) => {
    const location = useLocation()
    const user = useContext(UserContext)

    const pathname = (pathname) => {
        if (pathname === location.pathname) {
            return 'active'
        }
        return ''
    }

    if (location.pathname === '/admin/login') {
        return null
    } else if (location.pathname.indexOf('/admin') !== -1 && user.isAuth) {
        return <AdminHeader pathname={pathname} />
    } else if (location.pathname.indexOf('/admin') !== -1 && !user.isAuth) {
        return <Navigate form={location.pathname} to="/erreur404" />
    }

    return <VisitorHeader pathname={pathname} scrollY={scrollY} />
}

export default Header
