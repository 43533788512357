import React from 'react'

const Page404 = (props) => {
    return (
        <div className="wrapperBanner --globalBanner">
            <span className="d-flex">
                <h1 className="upp">Erreur 404</h1>
                <h6 className="upp">Page introuvable</h6>
            </span>
        </div>
    )
}

export default Page404
