import React, { forwardRef } from 'react'
import { TwitchEmbed } from 'react-twitch-embed'

const TwitchStream = ({ channel, style, id, theme, muted, onVideoPause }, ref) => {
    return (
        <div style={style} ref={ref}>
            <TwitchEmbed
                className="twitchStream"
                channel={channel}
                id={id}
                theme={theme}
                muted={muted}
                width="100%"
                height="100%"
                onVideoPause={onVideoPause}
            />
        </div>
    )
}

export default forwardRef(TwitchStream)
