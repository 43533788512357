import Axios from 'axios'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LoadingPage from '../../../components/global/LoadingPage'
import Table from '../../../components/global/table/Table'
import config from '../../../config/config'
import UserContext from '../../../contexts/UserContext'
import { catchErr } from '../../../error/catchErr'
import { SuccesSwal } from '../../../swal/swal'

const OpposingManager = () => {
    const { token } = useContext(UserContext)

    const [isLoading, setIsLoading] = useState(true)
    const [managersListData, setManagersListData] = useState([])
    const tableHeader = ['#', 'Nom du manager', 'Team externe', 'Création', '', '']

    useEffect(() => {
        if (isLoading && managersListData.length === 0) {
            Axios.get(`${config.backendApi}/api/get-all/opposing-managers?order[id]=asc`, config.apiHeader())
                .then((res) => {
                    const managers = res.data['hydra:member'].map((manager, index) => [
                        manager.id,
                        manager.pseudo,
                        manager.opposingTeam.teamName || 'n/a',
                        moment(manager.createdAt).format('DD/MM/YYYY'),
                        ['Modifier', `/admin/managers-externes/modifier/${manager.id}`],
                        'Supprimer',
                    ])

                    setManagersListData(managers)
                    setIsLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [isLoading, managersListData.length])

    if (isLoading) return <LoadingPage />

    const deleteById = (id) => {
        Axios.delete(`${config.backendApi}/api/opposing-managers/${id}`, config.apiHeader(token))
            .then((res) => {
                SuccesSwal('La suppression du manager est réussi', 'refresh')
            })
            .catch((err) => catchErr(err.response))
    }

    return (
        <div className="content">
            <div className="action">
                <Link to="/admin/managers-externes/creer" className="btn btn-success">
                    Créer un manager externe
                </Link>
            </div>
            {managersListData.length > 0 && (
                <Table dataList={managersListData} tableHeader={tableHeader} deleteById={deleteById} />
            )}
        </div>
    )
}

export default OpposingManager
