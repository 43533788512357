import React from 'react'

const GlobalBanner = ({ title, subTitle, children }) => {

    return (
        <div className="wrapperBanner --globalBanner">
            <span className="titleAndSubtitle">
                {title && <h1 className="upp">{title}</h1>}
                {subTitle && <h6 className="upp">{subTitle}</h6>}
                {children ? children : null}
            </span>
        </div>
    )
}

export default GlobalBanner
