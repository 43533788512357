import { icon, library } from '@fortawesome/fontawesome-svg-core'
import { faDiscord, faTwitch, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation } from 'react-router-dom'
import React from 'react'
import hashtagImg from '../../../assets/images/hashtag.svg'
import logo from '../../../assets/images/logo/logoTDGris.svg'

library.add([faTwitter, faDiscord, faYoutube, faTwitch, faShoppingCart, faEnvelope])

const Footer = () => {
    const location = useLocation()
    const iconTwitter = icon({ prefix: 'fab', iconName: 'twitter' })
    const iconYoutube = icon({ prefix: 'fab', iconName: 'youtube' })
    const iconDiscord = icon({ prefix: 'fab', iconName: 'discord' })
    const iconTwitch = icon({ prefix: 'fab', iconName: 'twitch' })
    const iconEnvelope = icon({ prefix: 'fas', iconName: 'envelope' })

    return (
        <footer>
            <div className="mediumContainer">
                <div className="footer_img">
                    <img src={hashtagImg} />
                    <img src={logo} />
                </div>
                <div className="middle_footer">
                    <div className="footer_social">
                        <a href="https://twitter.com/teamdivergentes" className="footer_socialIcon"target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={iconTwitter} /></a>
                        <a href="https://www.youtube.com/channel/UC5laAdDfyTTUSdK0t2wYx2g" className="footer_socialIcon"target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={iconYoutube} /></a>
                        <a href="https://discord.com/invite/mF67YZKnU3" className="footer_socialIcon"target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={iconDiscord} /></a>
                        <a href="https://www.twitch.tv/teamdivergentes" className="footer_socialIcon"target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={iconTwitch} /></a>
                        <a href="mailto:test@gmail.com" className="footer_socialIcon"target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={iconEnvelope} /></a>
                    </div>
                    <div className="footer_copyright">
                        Copyright © 2023 TeamDivergentes
                    </div>
                </div>
                <nav className="footer_nav">
                    <ul className="footer_nav_container_items">
                        <li className="footer_nav_item">
                            <Link to="/" className={`footer_nav_item_link ${location.pathname === '/' ? 'active' : ''}`}>accueil</Link>
                        </li>
                        <li className="footer_nav_item">
                            <Link to="/evenements" className={`footer_nav_item_link ${location.pathname === '/evenements' ? 'active' : ''}`}>évènements</Link>
                        </li>
                        <li className="footer_nav_item">
                            <Link to="/team" className={`footer_nav_item_link ${location.pathname === '/team' ? 'active' : ''}`}>team</Link>
                        </li>
                        <li className="footer_nav_item">
                            <Link to="/graphic-chart" className={`footer_nav_item_link ${location.pathname === '/graphic-chart' ? 'active' : ''}`}>pack graphique</Link>
                        </li>
                        <li className="footer_nav_item">
                            <Link to="/shop" className={`footer_nav_item_link ${location.pathname === '/shop' ? 'active' : ''}`}>boutique</Link>
                        </li>
                        <li className="footer_nav_item">
                            <Link to="/stream" className={`footer_nav_item_link ${location.pathname === '/stream' ? 'active' : ''}`}>stream</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </footer>
    )
}

export default Footer
