import React from 'react'
import Td from './Td'

const Tr = ({ dataItem, deleteById, id }) => {
    return (
        <tr>
            {dataItem.map((singleData, index) => (
                <Td key={`td${index}`} singleData={singleData} id={id} deleteById={deleteById} />
            ))}
        </tr>
    )
}

export default Tr
