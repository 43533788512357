import banniere_charte_graphique from './banniere-charte-graphique/banniere.png'
import banniere_charte from './banniere-charte-graphique/banniere_charte.png'
import banniere_logo from './banniere-charte-graphique/banniere_logo.png'
import charte_graphique from './banniere-charte-graphique/charte_graphique.png'
import logo_charte_graphique from './banniere-charte-graphique/logo.png'
import banniere from './banniere.jpg'
import banniere_2 from './banniere_2.jpg'
import banniere_final_2021_twitter from './banniere_final_2021_twitter.png'
import csgo_hill_master_series from './csgo_hill_master_series.png'
import icon from './icon.png'
import iconbleu from './iconbleu.png'
import iconred from './iconred.png'
import CSGO from './img-jeux/CSGO.png'
import lol from './img-jeux/lol.png'
import minecraft from './img-jeux/minecraft.png'
import overwatch from './img-jeux/overwatch.png'
import rainbowxsixsiege from './img-jeux/rainbowxsixsiege.jpg'
import rocket_league from './img-jeux/rocket_league.png'
import valorant from './img-jeux/valorant.png'
import league_of_legend from './league_of_legend.jpg'
import logo from './logo.png'
import LOGOpng3 from './LOGOpng3.png'
import logo_teamdivergente_for_hexagon from './logo_teamdivergente_for_hexagon.png'
import dtm_esports from './opponents/dtm_esports.png'
import logo_kaedes_esport from './opponents/logo_kaedes_esport.jpeg'
import tenacity from './opponents/tenacity.png'
import vitical_esports from './opponents/vitical_esports.png'
//import shopBackground from './shop/Background.png'
import csgo_team from './team/csgo_team.png'
import lol2 from './team/lol2.png'
import overwatch_team from './team/overwatch_team.png'
import valorant_team from './team/valorant_team.png'
import logo_blanc from './TeamDivergenteBlanc.png'
import affiche from './uploaded_files/affiche.png'

export const images = {
    affiche,
    banniere,
    banniere_2,
    banniere_charte,
    banniere_logo,
    banniere_charte_graphique,
    banniere_final_2021_twitter,
    charte_graphique,
    CSGO,
    csgo_team,
    csgo_hill_master_series,
    dtm_esports,
    icon,
    iconbleu,
    iconred,
    league_of_legend,
    lol,
    lol2,
    logo,
    LOGOpng3,
    logo_charte_graphique,
    logo_kaedes_esport,
    logo_teamdivergente_for_hexagon,
    minecraft,
    overwatch,
    overwatch_team,
    rainbowxsixsiege,
    rocket_league,
    //shopBackground,
    tenacity,
    valorant,
    valorant_team,
    vitical_esports,
    logo_blanc,
}
