import { ErrorSwal } from '../swal/swal'

export const catchErr = (error) => {
    const { status } = error.data
    let { detail } = error.data

    if (status === 401 && detail === 'Expired JWT Token') {
        detail = 'Votre session à expiré'
    }

    switch (status) {
        case 401:
            ErrorSwal(detail, true)
            break
        case 404:
        case 409:
            ErrorSwal(detail)
            break
        default:
    }
}
