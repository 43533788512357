import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingPage from '../../../components/global/LoadingPage'
import config from '../../../config/config'
import UserContext from '../../../contexts/UserContext'
import { catchErr } from '../../../error/catchErr'
import { translateRole } from '../../../models/roles'
import { SuccesSwal } from '../../../swal/swal'

const UserForm = () => {
    const { token } = useContext(UserContext)
    const { id } = useParams()
    const navigate = useNavigate()

    const [username, setUsername] = useState('')
    const [roles, setRoles] = useState('0')
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(!!id)

    useEffect(() => {
        if (isLoading) {
            Axios.get(`${config.backendApi}/api/get-one/users/${id}`, config.apiHeader())
                .then(({ data }) => {
                    setUsername(data.username)
                    setRoles(data.roles[0])
                    setEmail(data.email)
                    setIsLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [id, isLoading])

    if (isLoading) return <LoadingPage />

    const handleSubmit = () => {
        const data = {
            username,
            roles: [roles],
            password,
            email,
        }

        if (id) {
            Axios.patch(`${config.backendApi}/api/users/${id}`, data, config.apiHeader())
                .then(() => {
                    SuccesSwal(`L'utilisateur ${username} a bien été modifié.`)
                    navigate('/admin/utilisateur')
                })
                .catch((err) => catchErr(err.response))
        } else {
            Axios.post(`${config.backendApi}/api/users`, data, config.apiHeader(token))
                .then(() => {
                    SuccesSwal(`L'utilisateur ${username} a bien été créé.`)
                    navigate('/admin/utilisateur')
                })
                .catch((err) => catchErr(err.response))
        }
    }

    return (
        <div className="roles">
            <h1 className="col pageTitle">{id ? 'Modifier' : 'Créer'} un utilisateur</h1>
            <form className="editUserForm">
                <div className="mb-3 col">
                    <label htmlFor="username" className="form-label">
                        Nom d'utilisateur
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="username"
                        name="username"
                        placeholder="Le nom d'utilisateur"
                        required
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                    />
                </div>
                <div className="mb-3 col">
                    <label htmlFor="roles">Rôle</label>
                    <select
                        className="form-control form-select form-select-lg"
                        id="roles"
                        name="roles"
                        onChange={(e) => setRoles(e.target.value)}
                        value={roles}
                    >
                        <option value="0" disabled>
                            Sélectionnez un roles
                        </option>
                        {config.rolesArray.map((role) => (
                            <option key={role} value={role}>
                                {translateRole(role)}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-3 col">
                    <label htmlFor="password" className="form-label">
                        Mot de passe
                    </label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        placeholder="Le mot de passe"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className="mb-3 col">
                    <label htmlFor="email" className="form-label">
                        Email
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Le mot de passe"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                    />
                </div>
                <div className="col">
                    <button
                        className="btn btn-primary"
                        type="button"
                        onClick={handleSubmit}
                        disabled={!username || roles === 0 || !password || !email}
                    >
                        {id ? 'Modifier' : 'Créer'}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default UserForm
