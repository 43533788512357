import { icon } from '@fortawesome/fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronRight, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import News from '../components/news/News'
import config from '../config/config'
import { catchErr } from '../error/catchErr'
import maillot from '../assets/images/home/maillot.png'
import maillotLength from '../assets/images/home/maillot_length.png'
import shirt from '../assets/images/home/shirt.png'
import women from '../assets/images/home/women.png'
import group from '../assets/images/home/group.png'
import circle from '../assets/images/home/circle.png'
import sliderImgCar from '../assets/images/home/slider_car.png'
import sliderImgCarFront from '../assets/images/home/slider_car_front.png'
import sliderImgChair from '../assets/images/home/slider_chair.png'
import sponsorPulsar from '../assets/images/sponsor/pulsar.svg'
import xSponsor from '../assets/images/sponsor/X.svg'
import { Link } from 'react-router-dom'
import car from '../assets/images/home/car.svg'

library.add([faChevronRight, faEye])

const iconEye = icon({
    prefix: 'fas',
    iconName: 'eye',
})

const itemPerPage = 3
const sliderImages = [sliderImgCar, sliderImgCarFront, sliderImgChair]

const Home = () => {
    const [page, setPage] = useState(1)
    const [newsList, setNewsList] = useState([])
    const [totalItems, setTotalItems] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        if (isLoading && newsList.length === 0) {
            Axios.get(`${config.backendApi}/api/get-all/news?order[id]=asc`, config.apiHeader())
                .then((res) => {
                    const newsListData = res.data['hydra:member']
                    if (!totalItems) {
                        setTotalItems(res.data['hydra:totalItems'])
                    }
                    setNewsList(newsList.concat(newsListData))
                    setIsLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [isLoading, newsList, totalItems])
    
    useEffect(() => {
        const interval = setInterval(rightBtn, 5000)
        return () => clearInterval(interval)
    }, [currentIndex])

    function moveSlide(offset) {
        const allImages = document.querySelectorAll('.slider_img');
        const activeIndex = Array.from(allImages).findIndex(img => img.classList.contains('active'));
    
        if (activeIndex > -1) {
            const nextIndex = (activeIndex + offset + allImages.length) % allImages.length;
            allImages[activeIndex].classList.remove('active');
            allImages[nextIndex].classList.add('active');
            updatePoint(nextIndex);
        }
    }
    
    function updatePoint(index) {
        const points = document.querySelectorAll('.slider_point_navigation_container span');
        const activeIndex = Array.from(points).findIndex(point => point.classList.contains('active'));
    
        points[activeIndex].classList.remove('active');
        points[index].classList.add('active');
    }
    
    function leftBtn() {
        moveSlide(-1);
    }
    
    function rightBtn() {
        moveSlide(1);
    }
    


    const separatorItems = Array.from({ length: 10 }, (_, index) => (
        <React.Fragment key={index}>
            <a href="https://www.behance.net/Pulsarcorp" className="separatorLink" target="_blank" rel="noreferrer">
                <img className="separatorItem" src={sponsorPulsar} alt="logo sponsor Pulsar"/>
            </a>
            <img className="separatorItem" src={xSponsor} alt="logo X de séparation"/>
        </React.Fragment>
    ));

    return (
        <>
            <div className="color_effect left_top"></div>
            <div id="home_DVG">
                <div className="mediumContainer">
                    <div className="home_DVG_TOP">
                        <div className="home_DVG_TOP_item item_home_effect_hover">
                            <img className="icon" src={group} alt="icône de décoration" />
                            <Link to="/shop" className="btn_perso white_btn">
                                collection
                            </Link>
                        </div>
                        <div className="home_DVG_TOP_item item_home_effect_hover">
                            <img className="icon" src={women} alt="icône de décoration" />
                            <a className="btn_perso white_btn" href="https://twitter.com/teamdivergentes" target="_blank">
                                twitter
                            </a>
                        </div>
                        <div className="home_DVG_TOP_item slider_home">
                            <div className="slider_images_container">
                                <img className="slider_img active" src={sliderImgCar}/>
                                <img className="slider_img" src={sliderImgCarFront}/>
                                <img className="slider_img" src={sliderImgChair}/>
                            </div>
                            <div className="slider_arrow_navigation_container">
                                <span className="slider_arrow left" onClick={leftBtn}></span>
                                <span className="slider_arrow right" onClick={rightBtn}></span>
                            </div>
                            <div className="slider_point_navigation_container">
                                <span className="active"></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="separatorLine">
                    <div className='separatorLineContainer'>
                        {separatorItems}
                        <img src={car} className='car' />
                    </div>

                </div>
                <div className="smallContainer">
                    <section className="home_DVG_MIDDLE">
                        <div className="left">
                            <p className="title little_title">Qui sont réellement les Divergents ?</p>
                            <p className="title big_title">
                                Divergentes - Structure Esport, <span className="green_text">depuis</span>
                                <br />
                                <span className='date'>2017</span>
                                <div className="border_decoration_2017"><div className='left_square'></div></div>
                            </p>
                            <div className="container_description">
                                <p>Divergentes eSports est une organisation e-sportive crée en 2017.</p>
                                <p>Nous sommes passionnés par les jeux vidéo, et nous sommes fiers de proposer différentes sections de jeu, que vous pouvez découvrir dans l'onglet "Equipe".</p>
                                <p>Notre objectif principal est de conduire nos équipes vers des évènements LAN et de participer à une variété de tournois.</p>
                                <p>Nous croyons en l'esprit d'équipe, la compétition saine et la croissance individuelle au sein de notre communauté de joueurs.</p>
                                <p>Rejoignez-nous sur Discord pour rester connecté, discuter des dernières actualités, et participer à l'excitation de notre aventure e-sportive.</p>
                                <p>Ensemble, nous visons l'excellence et aspirons à créer des moments mémorables dans le monde compétitif des jeux vidéo.</p>
                            </div>
                        </div>
                        <div className="right">
                            <div className="length_item item_home_effect_hover">
                                <img src={maillotLength} alt="Habillage" />
                            </div>
                            <div className="container_right_items">
                                <img className="circle" src={circle} alt="Image animée de décoration" />
                                <div className="square_item item_home_effect_hover">
                                    <img src={shirt} alt="Habillage" />
                                </div>
                                <div className="square_item item_home_effect_hover">
                                    <img src={maillot} alt="Habillage" />
                                </div>
                            </div>
                        </div>
                        <div className="color_effect right_bottom"></div>
                    </section>
                    {/*
                    <div className="middle_widget">
                        <span></span>
                    </div>
                    */}
                </div>
                {/*
                <div className="smallContainer">
                    <div className="home_DVG_video">
                        <div className="top">
                            <p className="title little_title">Nous connaître en détails</p>
                            <p className="title big_title">Notre vidéo de présentation</p>
                        </div>
                    </div>
                </div>
                <div className="mediumContainer">
                    <div className="home_DVG_Article_container_title">
                        <div className="left">
                            <p className="title little_title">Que se passe t'il chez Divergentes ?</p>
                            <p className="title big_title">Nos articles</p>
                        </div>
                        <div className="right"></div>
                    </div>
                    <div className="home_DVG_Article_container">
                        <article className="article">
                            <div className="article_top"></div>
                            <div className="article_bottom">
                                <div className="article_author_date_container">
                                    <p className="author">Divergentes</p>
                                    <p className="date">13 Sep, 2023</p>
                                </div>
                                <p className="title">"La grenade ta r***", ce n'est pas ce que vous croyez..</p>
                            </div>
                        </article>
                        <article className="article">
                            <div className="article_top"></div>
                            <div className="article_bottom">
                                <div className="article_author_date_container">
                                    <p className="author">Divergentes</p>
                                    <p className="date">13 Sep, 2023</p>
                                </div>
                                <p className="title">"La grenade ta r***", ce n'est pas ce que vous croyez..</p>
                            </div>
                        </article>
                        <article className="article">
                            <div className="article_top"></div>
                            <div className="article_bottom">
                                <div className="article_author_date_container">
                                    <p className="author">Divergentes</p>
                                    <p className="date">13 Sep, 2023</p>
                                </div>
                                <p className="title">"La grenade ta r***", ce n'est pas ce que vous croyez..</p>
                            </div>
                        </article>
                    </div>
                    <div className="home_DVG_Article_decoration_bottom">
                        <div className="item"></div>
                        <div className="item">
                            <div className="line"></div>
                            <img className="icon" src={snakeIcon} alt="icône de décoration" />
                        </div>
                        <div className="item"></div>
                    </div>
                </div>*/}

                {newsList.map((news, index) => {
                    if (index < itemPerPage * page) return <News key={news.id} news={news} />
                    return null
                })}
                {itemPerPage * page < totalItems && (
                    <div className="blockCenter">
                        <div className="seeMore" onClick={() => setPage(page + 1)}>
                            <div className="seeMore-button">
                                <FontAwesomeIcon icon={iconEye} /> Voir plus d'évènements
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
export default Home
