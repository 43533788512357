import { icon, library } from '@fortawesome/fontawesome'
import { faDiscord, faTwitch, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import getImageServer from '../global/getImageServer'

library.add([faTwitter, faDiscord, faYoutube, faTwitch])

const iconTwitter = icon({ prefix: 'fab', iconName: 'twitter' })
const iconYoutube = icon({ prefix: 'fab', iconName: 'youtube' })
const iconDiscord = icon({ prefix: 'fab', iconName: 'discord' })
const iconTwitch = icon({ prefix: 'fab', iconName: 'twitch' })

const Game = ({ game, index, accordionContent, setAccordionContent, isResponsiveTablet, isResponsivePhone }) => {
    const displayLogo = (url) => {
        if (url.includes('twitter')) {
            return (
                <a href={url} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={iconTwitter} />
                </a>
            )
        }
        if (url.includes('twitch')) {
            return (
                <a href={url} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={iconTwitch} />
                </a>
            )
        }
        if (url.includes('youtube')) {
            return (
                <a href={url} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={iconYoutube} />
                </a>
            )
        }
        if (url.includes('discord')) {
            return (
                <a href={url} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={iconDiscord} />
                </a>
            )
        }
    }

    const managerResponsive = () => {
        if (isResponsivePhone) {
            return {
                display: 'block',
                width: '50%',
                borderBottom: '2px solid #1dfef6',
                margin: '0 auto',
                paddingRight: '0',
            }
        } else if (isResponsiveTablet) {
            return {
                display: 'flex',
                justifyContent: 'center',
                width: '50%',
                borderBottom: '2px solid #1dfef6',
                margin: '0 auto',
                flexDirection: 'row',
                paddingRight: '0',
            }
        } else {
            return {
                display: 'flex',
                flexDirection: 'column',
                width: '15%',
                margin: '0 auto',
                paddingRight: '20px',
            }
        }
    }

    return (
        <div
            id={`section${game.id}`}
            className="tab-pane"
            ref={(accordionContentRef) => {
                accordionContent[index] = accordionContentRef
                setAccordionContent(accordionContent)
            }}
        >
            {game.teams.length > 0 &&
                game.teams.map((team) => (
                    <div className="teamWrapper" key={team.id}>
                        <h6
                            className="upp"
                            style={{
                                textAlign: 'center',
                                margin: '20px 0',
                                fontSize: '20px',
                            }}
                        >
                            {team.teamName}
                        </h6>
                        <div
                            className="d-flex team-tab"
                            style={
                                isResponsiveTablet
                                    ? {
                                          display: 'block',
                                          position: 'relative',
                                          flexDirection: 'column-reverse',
                                          flexWrap: 'wrap',
                                          marginBottom: '30px',
                                          backgroundSize: 'cover',
                                      }
                                    : {
                                          display: 'block',
                                          position: 'relative',
                                          flexDirection: 'row-reverse',
                                          flexWrap: 'wrap',
                                          marginBottom: '30px',
                                          backgroundSize: 'cover',
                                      }
                            }
                        >
                            <div
                                className="teamBorder equipe-team col-md-6 col-sm-12 col-12 d-flex flex-center"
                                style={
                                    isResponsiveTablet
                                        ? {
                                              flexWrap: 'wrap',
                                              width: '75%',
                                              margin: 'auto',
                                          }
                                        : {
                                              flexWrap: 'wrap',
                                              width: '50%',
                                          }
                                }
                            >
                                {team.players.map((player) => (
                                    <div key={player.id} className="hexagon">
                                        <div className="hexagon small-back">
                                            <img
                                                tag="logo valorant"
                                                src={getImageServer(team.logo.mediaName)}
                                                alt=""
                                                style={{
                                                    borderRadius: '50%',
                                                }}
                                            />
                                        </div>

                                        <p className="name">
                                            {player.pseudo} ( {player.playerStation} )
                                        </p>
                                        <div className="socialNetwork">
                                            {player.socialNetworks.map((socialNetwork) => (
                                                <span key={socialNetwork}>{displayLogo(socialNetwork)}</span>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {team.manager && team.coach && (
                                <div className="manager-team col" style={managerResponsive()}>
                                    <div className="hexagon">
                                        <div className="hexagon small-back">
                                            <img
                                                tag="logo valorant"
                                                src={getImageServer(team.logo.mediaName)}
                                                alt=""
                                                style={{
                                                    borderRadius: '50%',
                                                }}
                                            />
                                        </div>
                                        <p className="name">{team.manager.pseudo} ( Manager )</p>
                                        <div className="socialNetworkCoach">
                                            {team.manager.socialNetworks.map((socialNetwork) => (
                                                <span key={socialNetwork}>{displayLogo(socialNetwork)}</span>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="hexagon hexadeux">
                                        <div className="hexagon small-back">
                                            <img
                                                tag="logo valorant"
                                                src={getImageServer(team.logo.mediaName)}
                                                alt=""
                                                style={{
                                                    borderRadius: '50%',
                                                }}
                                            />
                                        </div>
                                        <p className="name">{team.coach.pseudo} ( Coach )</p>
                                        <div className="socialNetworkCoach">
                                            {team.coach.socialNetworks.map((socialNetwork) => (
                                                <span key={socialNetwork}>{displayLogo(socialNetwork)}</span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div
                                className="description flex-center"
                                style={
                                    isResponsiveTablet
                                        ? {
                                              justifyContent: 'center',
                                              width: '75%',
                                              margin: '10px auto 30px',
                                              textAlign: 'center',
                                          }
                                        : {
                                              justifyContent: 'center',
                                              width: '30%',
                                              margin: 'auto',
                                          }
                                }
                            >
                                {team.description}
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    )
}

export default Game
