import Swal from 'sweetalert2'
import { disconnect } from '../globalAction/disconnect'

export const ErrorSwal = (message, goDisconnect = false) => {
    Swal.fire({
        title: 'Erreurs',
        icon: 'error',
        showCancelButton: false,
        html: message,
        timer: 5000,
        timerProgressBar: true,
        customClass: {
            content: 'swal-content',
        },
    }).then(() => {
        if (goDisconnect === true) {
            disconnect()
        }
    })
}

export const SuccesSwal = (message, redirect = null) => {
    Swal.fire({
        title: 'Succès',
        icon: 'success',
        showCancelButton: false,
        html: message,
        timer: 5000,
        timerProgressBar: true,
        customClass: {
            content: 'swal-content',
        },
    }).then(() => {
        if (redirect === 'refresh') {
            document.location.reload()
        }
    })
}
