import '../assets/scss/pages/_details.scss'

export default function detailsYinYangTshirt() {
    return (
        <div className="elementContent">
            <p className="description_modalDetail">
                Notre tee-shirt est composé à 100% de coton organic biologique <span>(grammage de 180 gr/m²)</span>.
            </p>
            <p className="description_modalDetail">
                Doux et confortable, il se porte en toute circonstance.
            </p>
            <p className="description_modalDetail">
                Disponible en 2 coupes <span>(H/F)</span>.
            </p>
        </div>
    )
}