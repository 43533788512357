const translation = (word, lang) => {
    if (lang === 'fr') {
        switch (word) {
            case 'January':
                return 'Janvier'
            case 'Febrary':
                return 'Février'
            case 'March':
                return 'Mars'
            case 'April':
                return 'Avril'
            case 'May':
                return 'Mai'
            case 'June':
                return 'Juin'
            case 'July':
                return 'Juillet'
            case 'August':
                return 'Août'
            case 'September':
                return 'Septembre'
            case 'October':
                return 'Octobre'
            case 'November':
                return 'Novembre'
            case 'December':
                return 'Décembre'
            default:
                return null
        }
    }
}

export default translation
