import styled from '@emotion/styled'

const ImagePreview = ({ imageNameInit }) => {
    return (
        <PreviewContainer>
            <PreviewWrapper>
                <img src={imageNameInit} alt="" />
            </PreviewWrapper>
        </PreviewContainer>
    )
}

const PreviewContainer = styled.div`
    position: relative;
`

const PreviewWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: white;
    width: 150px;
    height: 150px;
    margin-left: 0.5em;

    & img {
        width: 100px;
    }

    &::before {
        content: 'Image actuel';
        color: white;
        top: -2em;
        position: absolute;
    }
`

export default ImagePreview
