import styled from '@emotion/styled'
import { faChevronRight, faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import getImageServer from '../global/getImageServer'

const News = ({ news }) => {
    const [isShow, setIsSetshow] = useState(false)

    return (
        <>
            <NewsWrapper>
                <Article
                    onClick={() => {
                        setIsSetshow(true)
                    }}
                    backgroundImage={getImageServer(news.image.mediaName)}
                >
                    <Category>{news.category.name}</Category>
                    <Title>{news.title}</Title>
                    <PreviewBtn>
                        <FontAwesomeIcon icon={faChevronRight} className="icon" />
                    </PreviewBtn>
                </Article>
            </NewsWrapper>

            <CustomModal show={isShow}>
                <Modal.Header>
                    <Modal.Title>{news.title}</Modal.Title>
                    <CustomModalCloseBtn onClick={() => setIsSetshow(false)}>
                        <FontAwesomeIcon icon={faX} />
                    </CustomModalCloseBtn>
                </Modal.Header>
                <CustomModalBody>
                    <p>
                        <strong>{news.title} :</strong>
                    </p>
                    <p>{news.content}</p>
                </CustomModalBody>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsSetshow(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </CustomModal>
        </>
    )
}

const CustomModal = styled(Modal)`
    color: black;
`

const CustomModalBody = styled(Modal.Body)`
    overflow: auto;

    & * {
        color: black;
    }
`

const CustomModalCloseBtn = styled(Button)`
    background: none;
    border: none;

    & path {
        color: black;
    }

    &:hover,
    &:focus {
        background: none;
        border: none;
        color: black !important;
        opacity: 0.8;
    }
`

const NewsWrapper = styled.div`
    width: 20%;
`
const Article = styled.article`
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    height: 325px;
    overflow: hidden;
    background-image: url(${(props) => props.backgroundImage});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    cursor: pointer;
    margin: 15px;
    padding: 10px;
    transition: 0.25s;
    position: relative;
    z-index: 1;

    &:hover {
        transform: scale(1.05);
        transition: 0.25s;
    }

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
    }
`

const Title = styled.p`
    font-weight: bold;
    text-transform: uppercase;
`

const Category = styled.p`
    font-weight: bold;
    color: #686868;
`

const PreviewBtn = styled.div`
    display: flex;
    position: absolute;
    width: 60px;
    height: 60px;
    right: 0;
    bottom: 0;
    background: #1dfef6;

    & i {
        font-size: 50px;
        margin: auto;
        display: block;
    }
`

export default News
