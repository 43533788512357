export const Role = {
    ROLE_USER: 'ROLE_USER',
    ROLE_CM: 'ROLE_CM',
    ROLE_ADMIN: 'ROLE_ADMIN',
    ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
}

export function translateRole(role) {
    if (role === 'ROLE_USER') return 'Utilisateur'
    if (role === 'ROLE_CM') return 'Community manager'
    if (role === 'ROLE_ADMIN') return 'Administrateur'
    return 'Super Administrateur'
}
