import { icon, library } from '@fortawesome/fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GlobalBanner from '../../components/global/GlobalBanner'
import LoadingPage from '../../components/global/LoadingPage'
import MainContainer from '../../components/global/MainContainer'
import translation from '../../components/global/translation'
import config from '../../config/config'
import { catchErr } from '../../error/catchErr'

library.add(faEye)

const iconEye = icon({
    prefix: 'fas',
    iconName: 'eye',
})

const Events = () => {
    const [page, setPage] = useState(1)
    const [totalItems, setTotalItems] = useState(null)
    const [eventList, setEventList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const itemPerPage = 5

    useEffect(() => {
        if (isLoading && eventList.length === 0) {
            Axios.get(`${config.backendApi}/api/get-all/events?order[id]=asc`, config.apiHeader())
                .then((res) => {
                    if (totalItems === null) {
                        setTotalItems(res.data['hydra:totalItems'])
                    }
                    setEventList(eventList.concat(res.data['hydra:member']))
                    setIsLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [eventList, isLoading, totalItems])

    if (isLoading) return <LoadingPage />

    return (
        <>
            <GlobalBanner title="évènements" subTitle="évènements et Matchs" />
            <section className="content sbc">
                <MainContainer>
                    <div className="all-evenements">
                        {eventList.map((event, index) => {
                            if (index < itemPerPage * page) {
                                let month = moment(event.date).format('MMMM')
                                return (
                                    <Link
                                        key={event.id}
                                        to={`/evenements/${event.id}`}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <article
                                            onMouseEnter={(e) => {
                                                e.target.style.transform = 'translateY(-5px)'
                                            }}
                                            onMouseOut={(e) => {
                                                e.target.style.transform = 'translateY(0px)'
                                            }}
                                            style={{
                                                display: 'flex',
                                                cursor: 'pointer',
                                                margin: '30px 0',
                                                marginTop: '30px',
                                                background:
                                                    'linear-gradient(90deg,rgba(0,20,19,.9098039215686274) 26%,#030303)',
                                                position: 'relative',
                                                zIndex: 1,
                                                transition: '.25s',
                                                backgroundSize: '100%',
                                                height: '90px',
                                                alignItems: 'center',
                                            }}
                                            className="evenements"
                                        >
                                            <div
                                                className="evenements-time"
                                                style={{
                                                    alignItems: 'center',
                                                    background: '#1dfef6',
                                                    minWidth: ' 90px',
                                                    height: '100%',
                                                    textAlign: 'center',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    pointerEvents: 'none',
                                                }}
                                            >
                                                <div className="upp" style={{ color: 'black' }}>
                                                    <div
                                                        style={{
                                                            color: 'black',
                                                            fontSize: '20px',
                                                            fontWeight: 700,
                                                            pointerEvents: 'none',
                                                        }}
                                                    >
                                                        {moment(event.date).format('DD')}
                                                    </div>
                                                    <div
                                                        style={{
                                                            color: 'black',
                                                            fontSize: '20px',
                                                            fontWeight: 700,
                                                            pointerEvents: 'none',
                                                        }}
                                                    >
                                                        {translation(month, 'fr')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="evenements-title"
                                                style={{ paddingLeft: '15px', pointerEvents: 'none' }}
                                            >
                                                <div className="upp" style={{ pointerEvents: 'none' }}>
                                                    {event.title}
                                                </div>
                                                <p
                                                    style={{
                                                        paddingLeft: '15px',
                                                        margin: 0,
                                                        pointerEvents: 'none',
                                                    }}
                                                >
                                                    {event.game.gameName}
                                                </p>
                                            </div>
                                        </article>
                                    </Link>
                                )
                            }
                            return null
                        })}
                    </div>
                    {itemPerPage * page < totalItems && (
                        <div className="blockCenter">
                            <div className="seeMore" onClick={() => setPage(page + 1)}>
                                <div className="seeMore-button">
                                    <FontAwesomeIcon icon={iconEye} /> Voir plus d'évènements
                                </div>
                            </div>
                        </div>
                    )}
                </MainContainer>
            </section>
        </>
    )
}

export default Events
