import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LoadingPage from '../../../components/global/LoadingPage'
import Table from '../../../components/global/table/Table'
import config from '../../../config/config'
import UserContext from '../../../contexts/UserContext'
import { catchErr } from '../../../error/catchErr'
import { SuccesSwal } from '../../../swal/swal'

const Category = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [categoriesListData, setCategoriesListData] = useState([])
    const tableHeader = ['#', 'Nom de la catégorie', '', '']

    const { token } = useContext(UserContext)

    useEffect(() => {
        if (isLoading && categoriesListData.length === 0) {
            Axios.get(`${config.backendApi}/api/get-all/categories?order[id]=asc`, config.apiHeader())
                .then((res) => {
                    const categories = res.data['hydra:member'].map((category) => [
                        category.id,
                        category.name,
                        ['Modifier', `/admin/categorie/modifier/${category.id}`],
                        'Supprimer',
                    ])

                    setCategoriesListData(categories)
                    setIsLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [categoriesListData.length, isLoading])

    if (isLoading) <LoadingPage />

    const deleteById = (id) => {
        Axios.delete(`${config.backendApi}/api/categories/${id}`, config.apiHeader(token))
            .then(() => {
                SuccesSwal('La suppression de cette catégorie est réussi ', 'refresh')
            })
            .catch((err) => catchErr(err.response))
    }

    return (
        <div className="content">
            <div className="action">
                <Link to="/admin/categorie/creer" className="btn btn-success">
                    Créer une catégorie
                </Link>
            </div>
            {categoriesListData.length > 0 && (
                <Table dataList={categoriesListData} tableHeader={tableHeader} deleteById={deleteById} />
            )}
        </div>
    )
}

export default Category
