import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import ImageUploader from 'react-images-upload'
import { useNavigate, useParams } from 'react-router-dom'
import ImagePreview from '../../../components/form/ImagePreview'
import getImageServer from '../../../components/global/getImageServer'
import LoadingPage from '../../../components/global/LoadingPage'
import config from '../../../config/config'
import UserContext from '../../../contexts/UserContext'
import { catchErr } from '../../../error/catchErr'
import { SuccesSwal } from '../../../swal/swal'

const NewsForm = () => {
    const { id } = useParams()
    const { id: userId, token } = useContext(UserContext)

    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    const [category, setCategory] = useState('0')
    const [imageNameInit, setImageNameInit] = useState(undefined)
    const [imageName, setImageName] = useState(undefined)
    const [categoriesList, setCategoriesList] = useState([])
    const [newsLoading, setNewsLoading] = useState(!!id)
    const [categoriesLoading, setCategoriesLoading] = useState(true)

    useEffect(() => {
        if (newsLoading) {
            Axios.get(`${config.backendApi}/api/get-one/news/${id}`, config.apiHeader())
                .then(({ data }) => {
                    console.log(data)
                    setTitle(data.title)
                    setContent(data.content)
                    setImageNameInit(getImageServer(data.image.mediaName))
                    setCategory(data.category.id)
                    setNewsLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [id, newsLoading])

    useEffect(() => {
        if (categoriesLoading && categoriesList.length === 0) {
            Axios.get(`${config.backendApi}/api/get-all/categories?order[id]=asc`, config.apiHeader())
                .then((res) => {
                    setCategoriesList(res.data['hydra:member'])
                    setCategoriesLoading(false)
                })
                .catch((err) => catchErr((err) => err.response))
        }
    }, [categoriesList.length, categoriesLoading])

    const navigate = useNavigate()

    if (newsLoading) return <LoadingPage />

    const handleSubmit = () => {
        const formData = new FormData()

        if (imageName !== undefined) {
            formData.append('imageName', imageName[0])
        }

        formData.append('title', title)
        formData.append('content', content)
        formData.append('category', category)
        formData.append('author', userId)

        if (!id) {
            Axios.post(`${config.backendApi}/api/news`, formData, config.apiHeader(token))
                .then(() => {
                    SuccesSwal(`L'article ${title} a bien été modifié.`)
                    navigate('/admin/article')
                })
                .catch((err) => catchErr(err.response))
        } else {
            Axios.post(`${config.backendApi}/api/news/${id}?_method=PATCH`, formData, config.apiHeader(token))
                .then(() => {
                    SuccesSwal(`L'article ${title} a bien été modifié.`)
                    navigate('/admin/article')
                })
                .catch((err) => catchErr(err.response))
        }
    }

    return (
        <div className="content">
            <h1 className="pageTitle col">{id ? 'Modifier' : 'Créer'} un article</h1>
            <form className="editNewsForm">
                <div className="mb-3 col">
                    <label htmlFor="title" className="form-label">
                        Titre
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        placeholder="Le titre"
                        required
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
                <div className="mb-3 col">
                    <label htmlFor="content" className="form-label">
                        Contenu
                    </label>
                    <textarea
                        className="form-control"
                        id="content"
                        name="content"
                        rows="5"
                        placeholder="Le contenu"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    ></textarea>
                </div>
                <div className="mb-3 col">
                    <label htmlFor="category">Catégorie</label>
                    <select
                        className="form-control form-select form-select-lg"
                        id="category"
                        name="category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                    >
                        <option value="0" disabled>
                            Sélectionnez la categorie
                        </option>
                        {categoriesList.map((category) => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-3 col">
                    <label htmlFor="imageName" className="form-label">
                        Image
                    </label>
                    <div className="picture">
                        <ImageUploader
                            withIcon={true}
                            buttonText="Choisir une image"
                            onChange={(imageName) => setImageName(imageName)}
                            imgExtension={['.jpg', '.gif', '.png']}
                            maxFileSize={5242880}
                            withPreview
                            singleImage
                        />
                        {id && <ImagePreview imageNameInit={imageNameInit} />}
                    </div>
                </div>
                <div className="col">
                    <button
                        className="btn btn-primary"
                        type="button"
                        onClick={handleSubmit}
                        disabled={!title || !content || category === '0' || (!imageName && !imageNameInit)}
                    >
                        {id ? 'Modifier' : 'Créer'}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default NewsForm
