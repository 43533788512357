import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import ImageUploader from 'react-images-upload'
import { useNavigate, useParams } from 'react-router-dom'
import ImagePreview from '../../../components/form/ImagePreview'
import getImageServer from '../../../components/global/getImageServer'
import LoadingPage from '../../../components/global/LoadingPage'
import config from '../../../config/config'
import UserContext from '../../../contexts/UserContext'
import { catchErr } from '../../../error/catchErr'
import { SuccesSwal } from '../../../swal/swal'

const GameForm = () => {
    const { token } = useContext(UserContext)
    const { id } = useParams()
    const navigate = useNavigate()

    const [gameName, setGameName] = useState('')
    const [imageNameInit, setImageNameInit] = useState(undefined)
    const [imageName, setImageName] = useState(undefined)
    const [isLoading, setIsLoading] = useState(!!id)

    useEffect(() => {
        if (isLoading) {
            Axios.get(`${config.backendApi}/api/get-one/games/${id}`, config.apiHeader())
                .then(({ data }) => {
                    setImageNameInit(getImageServer(data.gameLogo.mediaName))
                    setGameName(data.gameName)
                    setIsLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [id, isLoading])

    if (isLoading) return <LoadingPage />

    const handleSubmit = () => {
        const formData = new FormData()

        if (imageName !== undefined) {
            formData.append('imageName', imageName[0])
        }

        formData.append('gameName', gameName)

        if (id) {
            Axios.post(`${config.backendApi}/api/games/${id}?_method=PATCH`, formData, config.apiHeader(token))
                .then(() => {
                    SuccesSwal(`Le jeu a bien été modifié par ${gameName}.`)
                    navigate('/admin/jeu')
                })
                .catch((err) => catchErr(err.response))
        } else {
            Axios.post(`${config.backendApi}/api/games`, formData, config.apiHeader(token))
                .then(() => {
                    SuccesSwal(`Le jeu ${gameName} à bien été créé.`)
                    navigate('/admin/jeu')
                })
                .catch((err) => catchErr(err.response))
        }
    }

    return (
        <div className="content">
            <h1 className="col pageTitle">{id ? 'Modifier' : 'Créer'} un jeu</h1>
            <form className="createCategoryForm">
                <div className="mb-3 col">
                    <label htmlFor="title" className="form-label">
                        Nom du jeu
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="gameName"
                        name="gameName"
                        placeholder="Le nom du jeu"
                        required
                        value={gameName}
                        onChange={(e) => setGameName(e.target.value)}
                    />
                </div>
                <div className="mb-3 col">
                    <label htmlFor="imageName" className="form-label">
                        Image
                    </label>
                    <div className="picture">
                        <ImageUploader
                            withIcon={true}
                            buttonText="Choisir une image"
                            onChange={(imageName) => setImageName(imageName)}
                            imgExtension={['.jpg', '.gif', '.png']}
                            maxFileSize={5242880}
                            withPreview
                            singleImage
                        />
                        {id && <ImagePreview imageNameInit={imageNameInit} />}
                    </div>
                </div>
                <div className="col">
                    <button className="btn btn-primary" type="button" onClick={handleSubmit}>
                        {id ? 'Modifier' : 'Créer'}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default GameForm
