import { library } from '@fortawesome/fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingPage from '../../../components/global/LoadingPage'
import config from '../../../config/config'
import UserContext from '../../../contexts/UserContext'
import { catchErr } from '../../../error/catchErr'
import { SuccesSwal } from '../../../swal/swal'

library.add([faPlus, faTrash])

const iconPlus = icon({
    prefix: 'fas',
    iconName: 'plus',
})

const iconTrash = icon({
    prefix: 'fas',
    iconName: 'trash',
})

const PlayerForm = () => {
    const { token } = useContext(UserContext)
    const { id } = useParams()
    const navigate = useNavigate()

    const [pseudo, setPseudo] = useState('')
    const [playerStation, setPlayerStation] = useState('')
    const [isReplacementPlayer, setIsReplacementPlayer] = useState(false)
    const [socialNetworks, setSocialNetworks] = useState([])
    const [games, setGames] = useState([])
    const [game, setGame] = useState('0')
    const [teams, setTeams] = useState([])
    const [team, setTeam] = useState('0')

    const [isPlayerLoading, setIsPlayerLoading] = useState(!!id)
    const [isTeamLoading, setIsTeamLoading] = useState(true)
    const [isGameLoading, setIsGameLoading] = useState(true)

    useEffect(() => {
        if (isPlayerLoading) {
            Axios.get(`${config.backendApi}/api/get-one/players/${id}`, config.apiHeader())
                .then(({ data }) => {
                    setPseudo(data.pseudo)
                    setPlayerStation(data.playerStation)
                    setSocialNetworks(data.socialNetworks)
                    setIsReplacementPlayer(data.isReplacementPlayer)
                    setGame(data.game.id)
                    setTeam(data.team.id)
                    setIsPlayerLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [id, isPlayerLoading])

    useEffect(() => {
        if (isGameLoading && games.length === 0) {
            Axios.get(`${config.backendApi}/api/get-all/games`, config.apiHeader())
                .then(({ data }) => {
                    setGames(data['hydra:member'])
                    setIsGameLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [games.length, isGameLoading])

    useEffect(() => {
        if (isTeamLoading && teams.length === 0) {
            Axios.get(`${config.backendApi}/api/get-all/teams`, config.apiHeader())
                .then(({ data }) => {
                    setTeams(data['hydra:member'])
                    setIsTeamLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [isTeamLoading, teams.length])

    if (isGameLoading || isTeamLoading || isPlayerLoading) return <LoadingPage />

    const addInput = () => {
        setSocialNetworks([...socialNetworks, ''])
    }

    const removeInput = (index) => {
        setSocialNetworks([...socialNetworks.filter((_v, idx) => index !== idx)])
    }

    const handleSubmit = () => {
        const data = { pseudo, playerStation, isReplacementPlayer, socialNetworks, team, game }

        if (id) {
            Axios.patch(`${config.backendApi}/api/players/${id}`, data, config.apiHeader(token))
                .then(() => {
                    SuccesSwal(`Le joueur ${pseudo} a bien été modifié.`)
                    navigate('/admin/joueurs')
                })
                .catch((err) => catchErr(err.response))
        } else {
            Axios.post(`${config.backendApi}/api/players`, data, config.apiHeader(token))
                .then(() => {
                    SuccesSwal(`Le joueur ${pseudo} a bien été créé.`)
                    navigate('/admin/joueurs')
                })
                .catch((err) => catchErr(err.response))
        }
    }

    return (
        <div className="content">
            <h1 className="col pageTitle">{id ? 'Modifier' : 'Créer'} un joueur</h1>
            <form className="createUserForm">
                <div className="mb-3 col">
                    <label htmlFor="pseudo" className="form-label">
                        Pseudo
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="pseudo"
                        name="pseudo"
                        placeholder="Le nom du joueur"
                        required
                        onChange={(e) => setPseudo(e.target.value)}
                        value={pseudo}
                    />
                </div>
                <div className="mb-3 col">
                    <label className="form-label" htmlFor="playerStation">
                        Rôle
                    </label>
                    <input
                        className="form-control"
                        id="playerStation"
                        name="playerStation"
                        type="text"
                        placeholder="Le rôle"
                        required
                        onChange={(e) => setPlayerStation(e.target.value)}
                        value={playerStation}
                    />
                </div>
                <div className="mb-3 col">
                    <div className="form-label">Remplaçant</div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="isReplacementPlayer"
                            id="isReplacementPlayer1"
                            onChange={() => setIsReplacementPlayer(true)}
                            checked={isReplacementPlayer}
                        />
                        <label className="form-check-label" htmlFor="isReplacementPlayer1">
                            Oui
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="isReplacementPlayer"
                            id="isReplacementPlayer2"
                            onChange={() => setIsReplacementPlayer(false)}
                            checked={!isReplacementPlayer}
                        />
                        <label className="form-check-label" htmlFor="isReplacementPlayer2">
                            Non
                        </label>
                    </div>
                </div>

                <div className="mb-3 col inputWithButton">
                    <label className="form-label" htmlFor="socialNetworks">
                        Réseaux sociaux
                    </label>
                    {socialNetworks.map((socialNetwork, index) => (
                        <div key={`socialNetworks${index}`} className="inputWithButton-inputAndButton">
                            <div className="mr-3">
                                <input
                                    key={index}
                                    className="form-control mb-1 mr-1"
                                    type="text"
                                    onChange={(e) => {
                                        socialNetworks[index] = e.target.value
                                        setSocialNetworks([...socialNetworks])
                                    }}
                                    placeholder="Un réseau social"
                                    value={socialNetwork}
                                    required
                                />
                            </div>

                            <div className="inputWithButton-removeBtn">
                                <div key={index} className="inputWithButton-removeBtn-btnCreatePlayer">
                                    <FontAwesomeIcon icon={iconTrash} onClick={() => removeInput(index)} />
                                </div>
                            </div>
                        </div>
                    ))}

                    {socialNetworks.length < 4 && (
                        <div className="inputWithButton-inputAndButton-button" onClick={addInput}>
                            <FontAwesomeIcon icon={iconPlus} />
                        </div>
                    )}
                </div>

                <div className="mb-3 col">
                    <label htmlFor="game" className="form-label">
                        Jeu
                    </label>
                    <select
                        className="form-control form-select form-select-lg"
                        id="game"
                        name="game"
                        value={game}
                        onChange={(e) => setGame(e.target.value)}
                        required
                    >
                        <option value="0" disabled>
                            Choisissez un jeu
                        </option>
                        {games.map((game) => (
                            <option key={game.id} value={game.id}>
                                {game.gameName}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-3 col">
                    <label htmlFor="team" className="form-label">
                        Équipe
                    </label>
                    <select
                        className="form-control form-select form-select-lg"
                        id="team"
                        name="team"
                        value={team}
                        onChange={(e) => setTeam(e.target.value)}
                        required
                    >
                        <option value="0" disabled>
                            Choisissez une équipe
                        </option>
                        {teams.map((team) => (
                            <option key={team.id} value={team.id}>
                                {team.teamName}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col">
                    <button className="btn btn-primary" type="button" onClick={handleSubmit}>
                        {id ? 'Modifier' : 'Créer'}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default PlayerForm
