import { icon, library } from '@fortawesome/fontawesome-svg-core'
import { faCalendarAlt, faGamepad, faHome, faListAlt, faNewspaper, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { images } from '../../../../../assets/images/images'
import UserContext from '../../../../../contexts/UserContext'

library.add([faHome, faNewspaper, faUsers, faListAlt, faGamepad, faCalendarAlt])

const iconHome = icon({
    prefix: 'fas',
    iconName: 'home',
})

const iconNewsPaper = icon({
    prefix: 'fas',
    iconName: 'newspaper',
})

const iconUsers = icon({
    prefix: 'fas',
    iconName: 'users',
})

const iconListAlt = icon({
    prefix: 'fas',
    iconName: 'list-alt',
})

const iconGamepad = icon({
    prefix: 'fas',
    iconName: 'gamepad',
})

const iconCalendarAlt = icon({
    prefix: 'fas',
    iconName: 'calendar-alt',
})

const AdminHeader = (props) => {
    const { role } = useContext(UserContext)

    return (
        <div className="adminHeader">
            <div className="adminHeader__wrapper">
                <Link className="adminHeader__wrapper-logo" to="/admin/dashboard">
                    <img src={images.LOGOpng3} alt="logo divergente" />
                </Link>
                <nav className="sidebar">
                    <ul className="sidebar__navbar">
                        <li className={`bandeBleu sidebar__navbar-li `}>
                            <Link className="sidebar__navbar-li-link" to="/admin/dashboard">
                                <div className="sidebar__navbar-li-link-icon">
                                    <FontAwesomeIcon icon={iconHome} />
                                </div>
                                <div className="sidebar__navbar-li-link-text">Tableau de bord</div>
                            </Link>
                        </li>
                        <li className={`bandeBleu sidebar__navbar-li `}>
                            <Link className="sidebar__navbar-li-link" to="/admin/article">
                                <div className="sidebar__navbar-li-link-icon">
                                    <FontAwesomeIcon icon={iconNewsPaper} />
                                </div>
                                <div className="sidebar__navbar-li-link-text">Articles</div>
                            </Link>
                        </li>
                        {role !== 'ROLE_CM' && (
                            <>
                                <li className={`bandeBleu sidebar__navbar-li `}>
                                    <Link className="sidebar__navbar-li-link" to="/admin/categorie">
                                        <div className="sidebar__navbar-li-link-icon">
                                            <FontAwesomeIcon icon={iconListAlt} />
                                        </div>
                                        <div className="sidebar__navbar-li-link-text">Categories</div>
                                    </Link>
                                </li>
                                <li className={`bandeBleu sidebar__navbar-li `}>
                                    <Link className="sidebar__navbar-li-link" to="/admin/utilisateur">
                                        <div className="sidebar__navbar-li-link-icon">
                                            <FontAwesomeIcon icon={iconUsers} />
                                        </div>
                                        <div className="sidebar__navbar-li-link-text">Utilisateurs</div>
                                    </Link>
                                </li>
                                <li className={`bandeBleu sidebar__navbar-li `}>
                                    <Link className="sidebar__navbar-li-link" to="/admin/jeu">
                                        <div className="sidebar__navbar-li-link-icon">
                                            <FontAwesomeIcon icon={iconGamepad} />
                                        </div>
                                        <div className="sidebar__navbar-li-link-text">Jeux</div>
                                    </Link>
                                </li>
                                <li className={`bandeBleu sidebar__navbar-li `}>
                                    <Link className="sidebar__navbar-li-link" to="/admin/equipe">
                                        <div className="sidebar__navbar-li-link-icon">
                                            <FontAwesomeIcon icon={iconUsers} />
                                        </div>
                                        <div className="sidebar__navbar-li-link-text">Equipes internes</div>
                                    </Link>
                                </li>
                                <li className={`bandeBleu sidebar__navbar-li `}>
                                    <Link className="sidebar__navbar-li-link" to="/admin/equipes-externes">
                                        <div className="sidebar__navbar-li-link-icon">
                                            <FontAwesomeIcon icon={iconUsers} />
                                        </div>
                                        <div className="sidebar__navbar-li-link-text">Equipes externes</div>
                                    </Link>
                                </li>
                                <li className={`bandeBleu sidebar__navbar-li `}>
                                    <Link className="sidebar__navbar-li-link" to="/admin/joueurs">
                                        <div className="sidebar__navbar-li-link-icon">
                                            <FontAwesomeIcon icon={iconUsers} />
                                        </div>
                                        <div className="sidebar__navbar-li-link-text">Joueurs internes</div>
                                    </Link>
                                </li>
                                <li className={`bandeBleu sidebar__navbar-li `}>
                                    <Link className="sidebar__navbar-li-link" to="/admin/joueurs-externes">
                                        <div className="sidebar__navbar-li-link-icon">
                                            <FontAwesomeIcon icon={iconUsers} />
                                        </div>
                                        <div className="sidebar__navbar-li-link-text">Joueurs externes</div>
                                    </Link>
                                </li>
                                <li className={`bandeBleu sidebar__navbar-li `}>
                                    <Link className="sidebar__navbar-li-link" to="/admin/managers">
                                        <div className="sidebar__navbar-li-link-icon">
                                            <FontAwesomeIcon icon={iconUsers} />
                                        </div>
                                        <div className="sidebar__navbar-li-link-text">Managers internes</div>
                                    </Link>
                                </li>
                                <li className={`bandeBleu sidebar__navbar-li `}>
                                    <Link className="sidebar__navbar-li-link" to="/admin/managers-externes">
                                        <div className="sidebar__navbar-li-link-icon">
                                            <FontAwesomeIcon icon={iconUsers} />
                                        </div>
                                        <div className="sidebar__navbar-li-link-text">Managers externes</div>
                                    </Link>
                                </li>
                                <li className={`bandeBleu sidebar__navbar-li `}>
                                    <Link className="sidebar__navbar-li-link" to="/admin/coaches">
                                        <div className="sidebar__navbar-li-link-icon">
                                            <FontAwesomeIcon icon={iconUsers} />
                                        </div>
                                        <div className="sidebar__navbar-li-link-text">Coachs internes</div>
                                    </Link>
                                </li>
                                <li className={`bandeBleu sidebar__navbar-li `}>
                                    <Link className="sidebar__navbar-li-link" to="/admin/coaches-externes">
                                        <div className="sidebar__navbar-li-link-icon">
                                            <FontAwesomeIcon icon={iconUsers} />
                                        </div>
                                        <div className="sidebar__navbar-li-link-text">Coachs externes</div>
                                    </Link>
                                </li>
                            </>
                        )}

                        <li className={`bandeBleu sidebar__navbar-li `}>
                            <Link className="sidebar__navbar-li-link" to="/admin/evenement">
                                <div className="sidebar__navbar-li-link-icon">
                                    <FontAwesomeIcon icon={iconCalendarAlt} />
                                </div>
                                <div className="sidebar__navbar-li-link-text">Evenements</div>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default AdminHeader
