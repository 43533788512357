import React, { useState } from 'react'
import '../assets/scss/pages/_details.scss'

const Details = ({
    visible,
    setVisible,
    detailState,
    detailBackState,
    detailAdress,
    detailPrice,
    detailDesc,
    detailName,
}) => {
    const [currentMainImage, setCurrentMainImage] = useState(null)

    const updateStates = () => {
        setVisible(false)
        setCurrentMainImage(null)
    }

    return (
        <div className="globalContainerModal" style={{ display: visible ? 'flex' : 'none' }}>
            <div className="containerModal">
                <div className="topDescription_modalDetail">
                    <p className="titleDescription_modalDetail">{detailName}</p>
                    <p className="priceDescription_modalDetail">{detailPrice}€</p>
                    {detailDesc}
                </div>
                <div className="closeModal" onClick={() => updateStates()}></div>
                <div className="imgContainer">
                    <div className="imgItemPresentation">
                        <img className="" src={currentMainImage || detailState}/>
                    </div>
                    <div className="imgItemsSuggestion">
                        <div className="imgItemSuggestion" onClick={() => setCurrentMainImage(detailState)}>
                            <img className="" alt="" src={detailState} />
                        </div>
                        <div className="imgItemSuggestion" onClick={() => setCurrentMainImage(detailBackState)}>
                            <img className="" alt="" src={detailBackState}/>
                        </div>
                    </div>
                </div>
                <div className="descriptionContainer">
                    <div className="topDescription_modalDetail">
                        <p className="titleDescription_modalDetail">{detailName}</p>
                        <p className="priceDescription_modalDetail">{detailPrice}€</p>
                        {detailDesc}
                        <div className="separator_modalDetail"></div>
                    </div>
                    <div className="bottomDescription_modalDetail">
                        <p className="certification">
                            Notre textile et nos encres sont certifiés Oeko-Tex, un label de qualité garantissant le contrôle de l’absence de substances nocives dans nos produits.
                        </p>
                        <div className="madeInFrance">
                            <div className="flag">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <p>Fièrement personalisé en France</p>
                        </div>
                        <a href={detailAdress} className="btnModalbuyItem" target="_blank">acheter</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Details