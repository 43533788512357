import '../assets/scss/pages/_details.scss'

export default function SweatDesc() {
    return (
        <div className="elementContent">
            <p className="description_modalDetail">
                Notre Hoodie est composé de 80% de coton et 20% de polyester <span>(grammage de 280 gr/m²)</span>.
            </p>
            <p className="description_modalDetail">
                Un textile <span>doux et chaud</span> qui vous apporte confort et aisance.
            </p>
            <p className="description_modalDetail">
                Disponible en 2 coupes <span>(H/F)</span>.
            </p>
        </div>
    )
}
