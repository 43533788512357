import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import { images } from '../assets/images/images'

const GraphicChart = () => {
    const blocRef = useRef()
    const blocRefChart = useRef()

    const handleClick = () => {
        blocRef.current.style.height = blocRef.current.style.height === '0px' ? '175px' : '0px'
        blocRefChart.current.style.height = '0px'
    }

    const handleClickChart = () => {
        blocRefChart.current.style.height = blocRefChart.current.style.height === '0px' ? '350px' : '0px'
        blocRef.current.style.height = '0px'
    }

    return (
        <section className="allContainerGraphic">
            <div className="containerLogo">
                <div className="sectionLogo" onClick={handleClick}>
                    <div className="divImgBannier">
                        <img className="" src={images.banniere_charte} alt="" />
                    </div>
                    <div className="CharteDropDown" ref={blocRef}>
                        <Link to={images.charte_graphique} target="_blank" download>
                            <img src={images.logo} alt="" />
                            <div className="titleDownload">Charte Graphique</div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="containerLogo">
                <div className="sectionLogo" onClick={handleClickChart}>
                    <div className="divImgBannier">
                        <img className="" src={images.banniere_logo} alt="" />
                    </div>
                    <div className="CharteDropDown" ref={blocRefChart}>
                        <Link to={images.logo_charte_graphique} target="_blank" download>
                            <img src={images.logo} alt="" />
                            <div className="titleDownload">Logo</div>
                        </Link>

                        <Link to={images.banniere_charte_graphique} target="_blank" download>
                            <img src={images.logo} alt="" />
                            <div className="titleDownload">Bannière</div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default GraphicChart
