import Axios from 'axios'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LoadingPage from '../../../components/global/LoadingPage'
import Table from '../../../components/global/table/Table'
import config from '../../../config/config'
import UserContext from '../../../contexts/UserContext'
import { catchErr } from '../../../error/catchErr'
import { SuccesSwal } from '../../../swal/swal'

const OpposingPlayer = () => {
    const { token } = useContext(UserContext)

    const [isLoading, setIsLoading] = useState(true)
    const [players, setPlayers] = useState([])
    const tableHeader = ['#', 'Nom du joueur', 'Rôle', 'Remplaçant', 'Team', 'Jeu', 'Création', '', '']

    useEffect(() => {
        if (isLoading && players.length === 0) {
            Axios.get(`${config.backendApi}/api/get-all/opposing-players?order[id]=asc`, config.apiHeader())
                .then((res) => {
                    const players = res.data['hydra:member'].map((player, index) => [
                        player.id,
                        player.pseudo,
                        player.playerStation || 'n/a',
                        player.isReplacementPlayer ? 'oui' : 'non',
                        player.opposingTeam.teamName || 'n/a',
                        player.game.gameName || 'n/a',
                        moment(player.createdAt).format('DD/MM/YYYY'),
                        ['Modifier', `/admin/joueurs-externes/modifier/${player.id}`],
                        'Supprimer',
                    ])

                    setPlayers(players)
                    setIsLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [isLoading, players.length])

    if (isLoading) return <LoadingPage />

    const deleteById = (id) => {
        Axios.delete(`${config.backendApi}/api/opposing-players/${id}`, config.apiHeader(token))
            .then((res) => {
                SuccesSwal('La suppression du joueur est réussi', 'refresh')
            })
            .catch((err) => catchErr(err.response))
    }

    return (
        <div className="content">
            <div className="action">
                <Link to="/admin/joueurs-externes/creer" className="btn btn-success">
                    Créer un joueur externe
                </Link>
            </div>
            {players.length > 0 && <Table dataList={players} tableHeader={tableHeader} deleteById={deleteById} />}
        </div>
    )
}

export default OpposingPlayer
