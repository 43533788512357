import '../assets/scss/pages/_details.scss'

export default function MaillotDesc() {
    return (
        <div className="elementContent">
            <p className="description_modalDetail">
                Notre Tapis de souris personnalisé est conçu avec une <span>base en caoutchouc</span>.
            </p>
            <p className="description_modalDetail">
                Cette base <span>en caoutchouc et antidérapante</span> assure aux joueurs des <span>mouvements fluides et stables</span> avec la souris.
            </p>
        </div>
    )
}