import { icon, library } from '@fortawesome/fontawesome-svg-core'
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import getImageServer from '../../components/global/getImageServer'
import GlobalBanner from '../../components/global/GlobalBanner'
import LoadingPage from '../../components/global/LoadingPage'
import MainContainer from '../../components/global/MainContainer'
import config from '../../config/config'
import { catchErr } from '../../error/catchErr'

library.add(faCaretLeft)

const SingleViewEvents = () => {
    const [title, setTitle] = useState('')
    const [date, setDate] = useState('')
    const [teams, setTeams] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const navigate = useNavigate()
    const { id } = useParams()

    useEffect(() => {
        if (isLoading && teams.length === 0) {
            Axios.get(`${config.backendApi}/api/get-one/events/${id}`, config.apiHeader())
                .then(({ data }) => {
                    setTitle(data.title)
                    setDate(data.date)
                    setTeams(data.teams)
                    setIsLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [id, isLoading, teams.length])

    if (isLoading) {
        return <LoadingPage />
    }

    const iconCaretLeft = icon({
        prefix: 'fas',
        iconName: 'caret-left',
    })

    return (
        <>
            <GlobalBanner
                title={title}
                subTitle={`Le ${moment(date).format('DD MMMM YYYY')} à ${moment(date).format('HH:mm')}`}
            />
            <section className="content sbc">
                <MainContainer>
                    <div className="container-child-content">
                        <li className="back-page">
                            <div onClick={() => navigate(-1)}>
                                <FontAwesomeIcon icon={iconCaretLeft} /> Retour
                            </div>
                        </li>
                    </div>
                    <div className="roster row">
                        <div className="localTeam col-md-5 col-sm-12">
                            <div className="head-roster">
                                <img src={getImageServer(teams[0].logo.mediaName)} alt="logo teamdivergente" />
                                <h2>
                                    <strong>{teams[0].teamName}</strong>
                                </h2>
                            </div>
                            <div className="rosterTeam d-flex">
                                {teams[0].players.length > 0 &&
                                    teams[0].players.map((player) => (
                                        <div key={player.id} className="hexagon">
                                            <div className="hexagon small-back">
                                                <img
                                                    className="rounded-circle"
                                                    src={getImageServer(teams[0].logo.mediaName)}
                                                    alt="logo teamdivergente"
                                                />
                                            </div>
                                            <p className="name">
                                                <strong>{player.pseudo}</strong>
                                            </p>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <div className="vsRoster col-md-2">
                            <div className="head-roster vsTop">
                                <span className="upp"> vs </span>
                            </div>
                        </div>
                        <div className="otherTeam col-md-5 col-sm-12">
                            <div className="head-roster">
                                <img src={getImageServer(teams[1].logo.mediaName)} alt="logo opponent" />
                                <h2>
                                    <strong>{teams[1].teamName}</strong>
                                </h2>
                            </div>
                            <div className="rosterTeam d-flex">
                                {teams[1].opposingPlayers.length > 0 &&
                                    teams[1].opposingPlayers.map((player) => (
                                        <div key={player.id} className="hexagon">
                                            <div className="hexagon small-back">
                                                <img
                                                    className="rounded-circle"
                                                    src={getImageServer(teams[1].logo.mediaName)}
                                                    alt="logo opponent"
                                                />
                                            </div>
                                            <p className="name">
                                                <strong>{player.pseudo}</strong>
                                            </p>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </MainContainer>
            </section>
        </>
    )
}

export default SingleViewEvents
