import React from 'react'
import Tr from './Tr'

const Table = ({ tableHeader, dataList, deleteById }) => {
    return (
        <table className="table">
            <thead>
                <tr>
                    {tableHeader.map((headerItem, index) => (
                        <th key={`th${index}`}>{headerItem}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {dataList.map((dataItem, index) => (
                    <Tr key={`tr${index}`} deleteById={deleteById} dataItem={dataItem} id={dataItem[0]} />
                ))}
            </tbody>
        </table>
    )
}

export default Table
