const env = 'prod'

const config = {}
if (env === 'dev') {
    config.backendApi = 'http://localhost:8000'
} else if (env === 'prod') {
    config.backendApi = 'https://api.teamdivergentes.fr'
}

config.apiHeader = (token) => {
    if (token !== null) {
        return {
            headers: { Authorization: token, 'content-type': 'application/json' },
        }
    }
    return { headers: { Authorization: {} } }
}

config.roles = {
    ROLE_ADMIN: 'ROLE_ADMIN',
    ROLE_CM: 'ROLE_CM',
}

config.rolesArray = [config.roles.ROLE_ADMIN, config.roles.ROLE_CM]

export default config
