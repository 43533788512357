import React from 'react'
import { Link } from 'react-router-dom'

const Td = ({ singleData, id, deleteById }) => {
    if (singleData === 'Supprimer') {
        return (
            <td className="remove">
                <button type="button" className="btn btn-outline-danger" onClick={() => deleteById(id)}>
                    {singleData}
                </button>
            </td>
        )
    } else if (Array.isArray(singleData) && singleData[0] === 'render') {
        return <td>{singleData[1]}</td>
    } else if (Array.isArray(singleData) && singleData[0] === 'Modifier') {
        return (
            <td className="edit">
                <Link to={singleData[1]} className="btn btn-outline-primary">
                    {singleData[0]}
                </Link>
            </td>
        )
    } else if (Array.isArray(singleData) && singleData[0] === 'Image' && singleData[1] !== null) {
        return (
            <td>
                <a href={singleData[1]}>
                    <img className="imgWidth100px" src={singleData[1]} alt={`${singleData[1]}`} />
                </a>
            </td>
        )
    }

    return <td>{singleData}</td>
}

export default Td
