import { library } from '@fortawesome/fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingPage from '../../../components/global/LoadingPage'
import config from '../../../config/config'
import UserContext from '../../../contexts/UserContext'
import { catchErr } from '../../../error/catchErr'
import { SuccesSwal } from '../../../swal/swal'

library.add([faPlus, faTrash])

const iconPlus = icon({
    prefix: 'fas',
    iconName: 'plus',
})

const iconTrash = icon({
    prefix: 'fas',
    iconName: 'trash',
})

const ManagerForm = () => {
    const { token } = useContext(UserContext)
    const { id } = useParams()
    const navigate = useNavigate()

    const [pseudo, setPseudo] = useState('')
    const [playerStation, setPlayerStation] = useState('')
    const [isReplacementPlayer, setIsReplacementPlayer] = useState(false)
    const [socialNetworks, setSocialNetworks] = useState([])
    const [teams, setTeams] = useState([])
    const [team, setTeam] = useState('0')

    const [isManagerLoading, setIsManagerLoading] = useState(!!id)
    const [isTeamLoading, setIsTeamLoading] = useState(true)

    useEffect(() => {
        if (isManagerLoading) {
            Axios.get(`${config.backendApi}/api/get-one/managers/${id}`, config.apiHeader())
                .then(({ data }) => {
                    setPseudo(data.pseudo)
                    setPlayerStation(data.playerStation)
                    setSocialNetworks(data.socialNetworks)
                    setIsReplacementPlayer(data.isReplacementPlayer)
                    setTeam(data.team.id)
                    setIsManagerLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [id, isManagerLoading])

    useEffect(() => {
        if (isTeamLoading && teams.length === 0) {
            Axios.get(`${config.backendApi}/api/get-all/teams`, config.apiHeader())
                .then(({ data }) => {
                    setTeams(data['hydra:member'])
                    setIsTeamLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [isTeamLoading, teams.length])

    if (isTeamLoading || isManagerLoading) return <LoadingPage />

    const addInput = () => {
        setSocialNetworks([...socialNetworks, ''])
    }

    const removeInput = (index) => {
        socialNetworks.filter((_v, idx) => index !== idx)
        console.log(socialNetworks.filter((_v, idx) => index !== idx))
        setSocialNetworks([...socialNetworks.filter((_v, idx) => index !== idx)])
    }

    const handleSubmit = () => {
        const data = { pseudo, playerStation, isReplacementPlayer, socialNetworks, team }

        if (id) {
            Axios.patch(`${config.backendApi}/api/managers/${id}`, data, config.apiHeader(token))
                .then(() => {
                    SuccesSwal(`Le manager ${pseudo} a bien été modifié.`)
                    navigate('/admin/managers')
                })
                .catch((err) => catchErr(err.response))
        } else {
            Axios.post(`${config.backendApi}/api/managers`, data, config.apiHeader(token))
                .then(() => {
                    SuccesSwal(`Le manager ${pseudo} a bien été créé.`)
                    navigate('/admin/managers')
                })
                .catch((err) => catchErr(err.response))
        }
    }

    return (
        <div className="content">
            <h1 className="col pageTitle">{id ? 'Modifier' : 'Créer'} un manager</h1>
            <form className="createUserForm">
                <div className="mb-3 col">
                    <label htmlFor="pseudo" className="form-label">
                        Pseudo
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="pseudo"
                        name="pseudo"
                        placeholder="Le nom du manager"
                        required
                        onChange={(e) => setPseudo(e.target.value)}
                        value={pseudo}
                    />
                </div>
                <div className="mb-3 col inputWithButton">
                    <label className="form-label" htmlFor="socialNetworks">
                        Réseaux sociaux
                    </label>
                    {console.log(socialNetworks)}
                    {socialNetworks.map((socialNetwork, index) => (
                        <div key={`socialNetworks${index}`} className="inputWithButton-inputAndButton">
                            <div className="mr-3">
                                <input
                                    key={index}
                                    className="form-control mb-1 mr-1"
                                    type="text"
                                    onChange={(e) => {
                                        socialNetworks[index] = e.target.value
                                        setSocialNetworks([...socialNetworks])
                                    }}
                                    placeholder="Un réseau social"
                                    value={socialNetwork}
                                    required
                                />
                            </div>

                            <div className="inputWithButton-removeBtn">
                                <div key={index} className="inputWithButton-removeBtn-btnCreatePlayer">
                                    <FontAwesomeIcon icon={iconTrash} onClick={() => removeInput(index)} />
                                </div>
                            </div>
                        </div>
                    )) || null}

                    {socialNetworks.length < 4 && (
                        <div className="inputWithButton-inputAndButton-button" onClick={addInput}>
                            <FontAwesomeIcon icon={iconPlus} />
                        </div>
                    )}
                </div>
                <div className="mb-3 col">
                    <label htmlFor="team" className="form-label">
                        Équipe
                    </label>
                    <select
                        className="form-control form-select form-select-lg"
                        id="team"
                        name="team"
                        value={team}
                        onChange={(e) => setTeam(e.target.value)}
                        required
                    >
                        <option value="0" disabled>
                            Choisissez une équipe
                        </option>
                        {teams.map((team) => (
                            <option key={team.id} value={team.id}>
                                {team.teamName}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col">
                    <button className="btn btn-primary" type="button" onClick={handleSubmit}>
                        {id ? 'Modifier' : 'Créer'}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default ManagerForm
