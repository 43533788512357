import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import getImageServer from '../../../components/global/getImageServer'
import LoadingPage from '../../../components/global/LoadingPage'
import Table from '../../../components/global/table/Table'
import config from '../../../config/config'
import UserContext from '../../../contexts/UserContext'
import { catchErr } from '../../../error/catchErr'
import { SuccesSwal } from '../../../swal/swal'

const Team = () => {
    const { token } = useContext(UserContext)

    const [isLoading, setIsLoading] = useState(true)
    const [teamsListData, setTeamsListData] = useState([])
    const tableHeader = ['#', "Nom de l'équipe", 'Jeu', 'Description', 'Logo', '', '']

    useEffect(() => {
        if (isLoading && teamsListData.length === 0) {
            Axios.get(`${config.backendApi}/api/get-all/teams?order[id]=asc`, config.apiHeader())
                .then((res) => {
                    let teams = res.data['hydra:member'].map((team) => [
                        team.id,
                        team.teamName,
                        team.game.gameName,
                        team.description,
                        team.logo !== null ? ['Image', getImageServer(team.logo.mediaName)] : 'n/a',
                        ['Modifier', `/admin/equipe/modifier/${team.id}`],
                        'Supprimer',
                    ])

                    setTeamsListData(teams)
                    setIsLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [isLoading, teamsListData.length])

    if (isLoading) return <LoadingPage />

    const deleteById = (id) => {
        Axios.delete(`${config.backendApi}/api/teams/${id}`, config.apiHeader(token))
            .then(() => {
                SuccesSwal('La suppression de cette équipe est réussi ', 'refresh')
            })
            .catch((err) => catchErr(err.response))
    }

    return (
        <div className="content">
            <div className="action">
                <Link to="/admin/equipe/creer" className="btn btn-success">
                    Créer une équipe
                </Link>
            </div>
            {teamsListData.length > 0 && (
                <Table dataList={teamsListData} tableHeader={tableHeader} deleteById={deleteById} />
            )}
        </div>
    )
}

export default Team
