import Axios from 'axios'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LoadingPage from '../../../components/global/LoadingPage'
import Table from '../../../components/global/table/Table'
import config from '../../../config/config'
import UserContext from '../../../contexts/UserContext'
import { catchErr } from '../../../error/catchErr'
import { SuccesSwal } from '../../../swal/swal'
import { SocialNetworks } from '../manager/Manager'

const Coach = () => {
    const { token } = useContext(UserContext)

    const [isLoading, setIsLoading] = useState(true)
    const [coachesListData, setCoachesListData] = useState([])
    const tableHeader = ['#', 'Nom du coach', 'Réseaux sociaux', 'Team', 'Création', '', '']

    useEffect(() => {
        if (isLoading && coachesListData.length === 0) {
            Axios.get(`${config.backendApi}/api/get-all/coaches?order[id]=asc`, config.apiHeader())
                .then((res) => {
                    const coaches = res.data['hydra:member'].map((coach, index) => [
                        coach.id,
                        coach.pseudo,
                        coach.socialNetworks
                            ? ['render', <SocialNetworks socialNetworks={coach.socialNetworks} lineIdx={index} />]
                            : 'n/a',
                        coach.team.teamName ? coach.team.teamName : 'n/a',
                        moment(coach.createdAt).format('DD/MM/YYYY'),
                        ['Modifier', `/admin/coaches/modifier/${coach.id}`],
                        'Supprimer',
                    ])

                    setCoachesListData(coaches)
                    setIsLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [coachesListData.length, isLoading])

    if (isLoading) return <LoadingPage />

    const deleteById = (id) => {
        Axios.delete(`${config.backendApi}/api/coaches/${id}`, config.apiHeader(token))
            .then(() => {
                SuccesSwal('La suppression du coach est réussi', 'refresh')
            })
            .catch((err) => catchErr(err.response))
    }

    return (
        <div className="content">
            <div className="action">
                <Link to="/admin/coaches/creer" className="btn btn-success">
                    Créer un coach
                </Link>
            </div>
            {coachesListData.length > 0 && (
                <Table dataList={coachesListData} tableHeader={tableHeader} deleteById={deleteById} />
            )}
        </div>
    )
}

export default Coach
