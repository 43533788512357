import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import ImageUploader from 'react-images-upload'
import { useNavigate, useParams } from 'react-router-dom'
import ImagePreview from '../../../components/form/ImagePreview'
import getImageServer from '../../../components/global/getImageServer'
import LoadingPage from '../../../components/global/LoadingPage'
import config from '../../../config/config'
import UserContext from '../../../contexts/UserContext'
import { catchErr } from '../../../error/catchErr'
import { SuccesSwal } from '../../../swal/swal'

const OpposingTeamForm = () => {
    const { token } = useContext(UserContext)
    const { id } = useParams()
    const navigate = useNavigate()

    const [teamName, setTeamName] = useState('')
    const [description, setDescription] = useState('')
    const [game, setGame] = useState('0')
    const [games, setGames] = useState([])

    const [logoInit, setLogoInit] = useState(undefined)
    const [logo, setLogo] = useState(undefined)
    const [isTeamLoading, setIsTeamLoading] = useState(!!id)
    const [isGameLoading, setIsGameLoading] = useState(true)

    useEffect(() => {
        if (isTeamLoading) {
            Axios.get(`${config.backendApi}/api/get-one/opposing-teams/${id}`, config.apiHeader())
                .then(({ data }) => {
                    setLogoInit(getImageServer(data.logo.mediaName))
                    setTeamName(data.teamName)
                    setDescription(data.description)
                    setGame(data.game.id)
                    setIsTeamLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [id, isTeamLoading])

    useEffect(() => {
        if (isGameLoading && games.length === 0) {
            Axios.get(`${config.backendApi}/api/get-all/games`, config.apiHeader())
                .then(({ data }) => {
                    setGames(data['hydra:member'])
                    setIsGameLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [games.length, isGameLoading])

    if (isTeamLoading || isGameLoading) return <LoadingPage />

    const handleSubmit = () => {
        const formData = new FormData()

        if (logo !== undefined) {
            formData.append('logo', logo[0])
        }

        if (teamName !== null) {
            formData.append('teamName', teamName)
        }

        if (description !== null) {
            formData.append('description', description)
        }

        if (game !== null) {
            formData.append('game', game)
        }

        if (id) {
            Axios.post(`${config.backendApi}/api/opposing-teams/${id}?_method=PATCH`, formData, config.apiHeader(token))
                .then(() => {
                    SuccesSwal(`L'équipe a bien été modifié.`)
                    navigate('/admin/equipes-externes', { replace: true })
                })
                .catch((err) => catchErr(err.response))
        } else {
            Axios.post(`${config.backendApi}/api/opposing-teams`, formData, config.apiHeader(token))
                .then(() => {
                    SuccesSwal(`L'équipe ${teamName} a bien été créé.`)
                    navigate('/admin/equipes-externes', { replace: true })
                })
                .catch((err) => catchErr(err.response))
        }
    }

    return (
        <div className="content">
            <h1 className="col pageTitle">{id ? 'Modifier' : 'Créer'} une équipe externe</h1>
            <form className="createCategoryForm">
                <div className="mb-3 col">
                    <label htmlFor="teamName" className="form-label">
                        Nom de l'équipe
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="teamName"
                        name="teamName"
                        placeholder="Le nom de l'équipe"
                        required
                        onChange={(e) => setTeamName(e.target.value)}
                        value={teamName}
                    />
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="description" className="form-label">
                        Description
                    </label>
                    <textarea
                        className="form-control"
                        id="description"
                        name="description"
                        rows="5"
                        placeholder="Votre contenu"
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                    ></textarea>
                </div>
                <div className="mb-3 col">
                    <label htmlFor="game" className="form-label">
                        Jeu
                    </label>
                    <select
                        className="form-control form-select form-select-lg"
                        id="game"
                        name="game"
                        defaultValue={game}
                        setGame
                        onChange={(e) => setGame(e.target.value)}
                        required
                    >
                        <option value="0" disabled>
                            Choisissez une équipe
                        </option>
                        {games.map((game) => (
                            <option key={game.id} value={game.id}>
                                {game.gameName}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-3 col">
                    <label htmlFor="imageName" className="form-label">
                        Image
                    </label>
                    <div className="picture">
                        <ImageUploader
                            withIcon={true}
                            buttonText="Choisir une image"
                            onChange={(image) => setLogo(image)}
                            imgExtension={['.jpg', '.gif', '.png']}
                            maxFileSize={5242880}
                            withPreview
                            singleImage
                        />
                        {id && <ImagePreview imageNameInit={logoInit} />}
                    </div>
                </div>
                <div className="col">
                    <button className="btn btn-primary" type="button" onClick={handleSubmit}>
                        {id ? 'Modifier' : 'Créer'}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default OpposingTeamForm
