import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingPage from '../../../components/global/LoadingPage'
import config from '../../../config/config'
import UserContext from '../../../contexts/UserContext'
import { catchErr } from '../../../error/catchErr'
import { SuccesSwal } from '../../../swal/swal'

const CategoryForm = () => {
    const { id } = useParams()
    const { token } = useContext(UserContext)

    const [name, setName] = useState('')
    const [isLoading, setIsLoading] = useState(!!id)

    useEffect(() => {
        if (isLoading) {
            Axios.get(`${config.backendApi}/api/get-one/categories/${id}`, config.apiHeader())
                .then(({ data }) => {
                    setName(data.name)
                    setIsLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [id, isLoading])

    const navigate = useNavigate()

    const handleSubmit = () => {
        if (!id) {
            Axios.post(`${config.backendApi}/api/categories`, { name }, config.apiHeader(token))
                .then(() => {
                    SuccesSwal(`La catégorie ${name} a bien été créé.`)
                    navigate('/admin/categorie')
                })
                .catch((err) => catchErr(err.response))
        } else {
            Axios.patch(`${config.backendApi}/api/categories/${id}`, { name }, config.apiHeader(token))
                .then(() => {
                    SuccesSwal(`La catégorie a bien été modifié par ${name}.`)
                    navigate('/admin/categorie')
                })
                .catch((err) => catchErr(err.response))
        }
    }

    if (isLoading) return <LoadingPage />

    return (
        <div className="content">
            <h1 className="col pageTitle">{id ? 'Modifier' : 'Créer'} une catégorie</h1>
            <form className="createCategoryForm">
                <div className="mb-3 col">
                    <label htmlFor="name" className="form-label">
                        Nom de la catégorie
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Le nom de la catégorie"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="col">
                    <button className="btn btn-primary" type="button" onClick={handleSubmit}>
                        {id ? 'Modifier' : 'Créer'}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default CategoryForm
