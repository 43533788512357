import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingPage from '../../../components/global/LoadingPage'
import config from '../../../config/config'
import UserContext from '../../../contexts/UserContext'
import { catchErr } from '../../../error/catchErr'
import { SuccesSwal } from '../../../swal/swal'

const OpposingCoachForm = () => {
    const { token } = useContext(UserContext)
    const { id } = useParams()
    const navigate = useNavigate()

    const [pseudo, setPseudo] = useState('')
    const [playerStation, setPlayerStation] = useState('')
    const [isReplacementPlayer, setIsReplacementPlayer] = useState(false)
    const [teams, setTeams] = useState([])
    const [team, setTeam] = useState('0')

    const [isManagerLoading, setIsManagerLoading] = useState(!!id)
    const [isTeamLoading, setIsTeamLoading] = useState(true)

    useEffect(() => {
        if (isManagerLoading) {
            Axios.get(`${config.backendApi}/api/get-one/opposing-coaches/${id}`, config.apiHeader())
                .then(({ data }) => {
                    setPseudo(data.pseudo)
                    setPlayerStation(data.playerStation)
                    setIsReplacementPlayer(data.isReplacementPlayer)
                    setTeam(data.opposingTeam.id)
                    setIsManagerLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [id, isManagerLoading])

    useEffect(() => {
        if (isTeamLoading && teams.length === 0) {
            Axios.get(`${config.backendApi}/api/get-all/opposing-teams`, config.apiHeader())
                .then(({ data }) => {
                    setTeams(data['hydra:member'])
                    setIsTeamLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [isTeamLoading, teams.length])

    if (isTeamLoading || isManagerLoading) return <LoadingPage />

    const handleSubmit = () => {
        const data = { pseudo, playerStation, isReplacementPlayer, socialNetwork: null, opposingTeam: team }

        if (id) {
            Axios.patch(`${config.backendApi}/api/opposing-coaches/${id}`, data, config.apiHeader(token))
                .then(() => {
                    SuccesSwal(`Le coach externe ${pseudo} a bien été modifié.`)
                    navigate('/admin/coaches-externes')
                })
                .catch((err) => catchErr(err.response))
        } else {
            Axios.post(`${config.backendApi}/api/opposing-coaches`, data, config.apiHeader(token))
                .then(() => {
                    SuccesSwal(`Le coach externe ${pseudo} a bien été créé.`)
                    navigate('/admin/coaches')
                })
                .catch((err) => catchErr(err.response))
        }
    }

    return (
        <div className="content">
            <h1 className="col pageTitle">{id ? 'Modifier' : 'Créer'} un coach externe</h1>
            <form className="createUserForm">
                <div className="mb-3 col">
                    <label htmlFor="pseudo" className="form-label">
                        Pseudo
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="pseudo"
                        name="pseudo"
                        placeholder="Le nom du coach"
                        required
                        onChange={(e) => setPseudo(e.target.value)}
                        value={pseudo}
                    />
                </div>
                <div className="mb-3 col">
                    <label htmlFor="team" className="form-label">
                        Équipe
                    </label>
                    <select
                        className="form-control form-select form-select-lg"
                        id="team"
                        name="team"
                        value={team}
                        onChange={(e) => setTeam(e.target.value)}
                        required
                    >
                        <option value="0" disabled>
                            Choisissez une équipe
                        </option>
                        {teams.map((team) => (
                            <option key={team.id} value={team.id}>
                                {team.teamName}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col">
                    <button className="btn btn-primary" type="button" onClick={handleSubmit}>
                        {id ? 'Modifier' : 'Créer'}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default OpposingCoachForm
