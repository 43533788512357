import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import UserInterface from './components/userInterface/UserInterface'
import About from './pages/About'
import GraphicChart from './pages/GraphicChart'
import Home from './pages/Home'
import Page404 from './pages/Page404'
import Palmares from './pages/Palmares'
import Partner from './pages/Partner'
import Shop from './pages/Shop'
import Staff from './pages/Staff'
import Team from './pages/Team'
import Twitch from './pages/Twitch'
import Dashboard from './pages/admin/Dashboard'
import LoginAdmin from './pages/admin/LoginAdmin'
import Category from './pages/admin/category/Category'
import CategoryForm from './pages/admin/category/CategoryForm'
import Coach from './pages/admin/coach/Coach'
import CoachForm from './pages/admin/coach/CoachForm'
import Event from './pages/admin/event/Event'
import EventForm from './pages/admin/event/EventForm'
import Game from './pages/admin/game/Game'
import GameForm from './pages/admin/game/GameForm'
import Manager from './pages/admin/manager/Manager'
import ManagerForm from './pages/admin/manager/ManagerForm'
import News from './pages/admin/news/News'
import NewsForm from './pages/admin/news/NewsForm'
import OpposingCoach from './pages/admin/opposingCoach/OpposingCoach'
import OpposingCoachForm from './pages/admin/opposingCoach/OpposingCoachForm'
import OpposingManager from './pages/admin/opposingManager/OpposingManager'
import OpposingManagerForm from './pages/admin/opposingManager/OpposingManagerForm'
import OpposingPlayer from './pages/admin/opposingPlayer/OpposingPlayer'
import OpposingPlayerForm from './pages/admin/opposingPlayer/OpposingPlayerForm'
import OpposingTeam from './pages/admin/opposingTeam/OpposingTeam'
import OpposingTeamForm from './pages/admin/opposingTeam/OpposingTeamForm'
import Player from './pages/admin/player/Player'
import PlayerForm from './pages/admin/player/PlayerForm'
import AdminTeam from './pages/admin/team/Team'
import TeamForm from './pages/admin/team/TeamForm'
import User from './pages/admin/user/User'
import UserForm from './pages/admin/user/UserForm'
import Events from './pages/events/Events'
import Score from './pages/events/Score'
import SingleViewEvents from './pages/events/SingleViewEvents'

const Router = () => {
    return (
        <BrowserRouter>
            <UserInterface>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/evenements" element={<Events />} />
                    <Route path="/evenements/:id" element={<SingleViewEvents />} />
                    <Route path="/team" element={<Team />} />
                    <Route path="/staff" element={<Staff />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/partner" element={<Partner />} />
                    <Route path="/score" element={<Score />} />
                    <Route path="/twitch" element={<Twitch />} />
                    <Route path="/palmares" element={<Palmares />} />
                    <Route path="/graphic-chart" element={<GraphicChart />} />
                    <Route path="/shop" element={<Shop />} />
                    <Route path="/admin/login" element={<LoginAdmin />} />
                    <Route path="/admin/dashboard" element={<Dashboard />} />
                    <Route path="/admin/article" element={<News />} />
                    <Route path="/admin/article/creer" element={<NewsForm />} />
                    <Route path="/admin/article/modifier/:id" element={<NewsForm />} />
                    <Route path="/admin/categorie" element={<Category />} />
                    <Route path="/admin/categorie/creer" element={<CategoryForm />} />
                    <Route path="/admin/categorie/modifier/:id" element={<CategoryForm />} />
                    <Route path="/admin/utilisateur" element={<User />} />
                    <Route path="/admin/utilisateur/creer" element={<UserForm />} />
                    <Route path="/admin/utilisateur/modifier/:id" element={<UserForm />} />
                    <Route path="/admin/joueurs" element={<Player />} />
                    <Route path="/admin/joueurs/creer" element={<PlayerForm />} />
                    <Route path="/admin/joueurs/modifier/:id" element={<PlayerForm />} />
                    <Route path="/admin/jeu" element={<Game />} />
                    <Route path="/admin/jeu/creer" element={<GameForm />} />
                    <Route path="/admin/jeu/modifier/:id" element={<GameForm />} />
                    <Route path="/admin/equipe" element={<AdminTeam />} />
                    <Route path="/admin/equipe/creer" element={<TeamForm />} />
                    <Route path="/admin/equipe/modifier/:id" element={<TeamForm />} />
                    <Route path="/admin/evenement" element={<Event />} />
                    <Route path="/admin/evenement/creer" element={<EventForm />} />
                    <Route path="/admin/evenement/modifier/:id" element={<EventForm />} />
                    <Route path="/admin/managers" element={<Manager />} />
                    <Route path="/admin/managers/creer" element={<ManagerForm />} />
                    <Route path="/admin/managers/modifier/:id" element={<ManagerForm />} />
                    <Route path="/admin/coaches" element={<Coach />} />
                    <Route path="/admin/coaches/creer" element={<CoachForm />} />
                    <Route path="/admin/coaches/modifier/:id" element={<CoachForm />} />
                    <Route path="/admin/managers-externes" element={<OpposingManager />} />
                    <Route path="/admin/managers-externes/creer" element={<OpposingManagerForm />} />
                    <Route path="/admin/managers-externes/modifier/:id" element={<OpposingManagerForm />} />
                    <Route path="/admin/coaches-externes" element={<OpposingCoach />} />
                    <Route path="/admin/coaches-externes/creer" element={<OpposingCoachForm />} />
                    <Route path="/admin/coaches-externes/modifier/:id" element={<OpposingCoachForm />} />
                    <Route path="/admin/joueurs-externes" element={<OpposingPlayer />} />
                    <Route path="/admin/joueurs-externes/creer" element={<OpposingPlayerForm />} />
                    <Route path="/admin/joueurs-externes/modifier/:id" element={<OpposingPlayerForm />} />
                    <Route path="/admin/equipes-externes" element={<OpposingTeam />} />
                    <Route path="/admin/equipes-externes/creer" element={<OpposingTeamForm />} />
                    <Route path="/admin/equipes-externes/modifier/:id" element={<OpposingTeamForm />} />
                    <Route element={<Page404 />} />
                </Routes>
            </UserInterface>
        </BrowserRouter>
    )
}

export default Router
