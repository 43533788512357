import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import UserContext from '../../../../../contexts/UserContext'

library.add(faUser)

const HeaderTop = () => {
    const navigate = useNavigate()
    const user = useContext(UserContext)

    const handleDisconnect = () => {
        localStorage.removeItem('token')
        user.isAuth = false
        user.token = undefined
        user.role = undefined

        navigate('/')
    }

    return (
        <div className="adminHeaderTop">
            <div className="adminHeaderTop__btnDeco" onClick={handleDisconnect}>
                Déconnexion
            </div>
        </div>
    )
}

export default HeaderTop
