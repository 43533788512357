import React, { useRef, useState } from 'react'

const width = (boxWidth, toLeft) => {
    return boxWidth / 2 + toLeft
}

const Palmares = () => {
    const container = useRef()
    const opacityDate = useRef()
    const date = useRef()
    const secondBox = useRef()
    const thirdBox = useRef()
    const file = useRef()
    const traitHorizontale = useRef()
    const dateWin = useRef()
    const firstTraitSecondBox = useRef()
    const secondTraitSecondBox = useRef()
    const thirdTraitSecondBox = useRef()
    const firstTraitThirdBox = useRef()
    const secondTraitThirdBox = useRef()
    const thirdTraitThirdBox = useRef()

    const scrollWidth = 30
    const [isAnimated, setIsAnimated] = useState(false)

    const wheel = (e) => {
        const toLeft = secondBox.current.offsetLeft

        container.current.scrollLeft += e.deltaY > 0 ? scrollWidth : -scrollWidth

        if (!isAnimated && e.deltaY > 0) {
            setIsAnimated(true)

            file.current.classList.remove('fileVerticalCut')
            file.current.classList.add('fileVerticalIn')

            dateWin.current.classList.remove('dateWinNone')
            dateWin.current.classList.add('dateWinVisible')

            traitHorizontale.current.classList.remove('traitHorizontaleNone')
            traitHorizontale.current.classList.add('traitHorizontaleVisible')

            opacityDate.current.classList.remove('opacityNoneDate')
            opacityDate.current.classList.add('inOpacityDate')

            date.current.classList.remove('opacityNoneDate')
            date.current.classList.add('inOpacityDate')
        }

        if (container.current.scrollLeft > width(secondBox.current.offsetWidth, 0)) {
            firstTraitSecondBox.current.classList.remove('invisible')
            firstTraitSecondBox.current.classList.add('visible')

            secondTraitSecondBox.current.classList.remove('invisible')
            secondTraitSecondBox.current.classList.add('visible')

            thirdTraitSecondBox.current.classList.remove('invisible')
            thirdTraitSecondBox.current.classList.add('visible')
        }

        if (container.current.scrollLeft > width(thirdBox.current.offsetWidth, toLeft)) {
            firstTraitThirdBox.current.classList.remove('invisible')
            firstTraitThirdBox.current.classList.add('visible')

            secondTraitThirdBox.current.classList.remove('invisible')
            secondTraitThirdBox.current.classList.add('visible')

            thirdTraitThirdBox.current.classList.remove('invisible')
            thirdTraitThirdBox.current.classList.add('visible')
        }
    }

    return (
        <section id="bloc" onWheel={wheel} ref={container}>
            <div className="box firstBox">
                <div className="noScalePalmares">
                    <p>Palmares</p>
                </div>
                <div className="scaleOnePalmares">
                    <p>Palmares</p>
                </div>
                <div className="scaleTwoPalmares">
                    <p>Palmares</p>
                </div>

                <div className="description">
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut vel doloremque ab voluptate illo
                        distinctio! Modi dolorum, fugiat at natus quibusdam pariatur tenetur beatae blanditiis
                        perferendis vel laborum excepturi alias? Lorem ipsum dolor sit amet consectetur adipisicing
                        elit. Aut vel doloremque ab voluptate illo distinctio!
                    </p>
                </div>

                <p className="opacityDate opacityNoneDate" ref={opacityDate}>
                    Juin 2019
                </p>

                <p className="date opacityNoneDate" ref={date}>
                    Juin 2019
                </p>

                <span className="file fileVerticalCut" ref={file}></span>
                <span className="traitHorizontale traitHorizontaleNone" ref={traitHorizontale}></span>
                <div className="dateWin dateWinNone" ref={dateWin}>
                    <p>
                        Juin :<br /> Lorem ipsum dolor sit amet
                    </p>
                </div>
            </div>

            <div className="box secondBox" ref={secondBox}>
                <div className="firstTraitSecondBox invisible" ref={firstTraitSecondBox}></div>
                <div className="secondTraitSecondBox invisible" ref={secondTraitSecondBox}></div>
                <div className="thirdTraitSecondBox invisible" ref={thirdTraitSecondBox}></div>
            </div>

            <div className="box thirdBox" ref={thirdBox}>
                <div className="firstTraitThirdBox invisible" ref={firstTraitThirdBox}></div>
                <div className="secondTraitThirdBox invisible" ref={secondTraitThirdBox}></div>
                <div className="thirdTraitThirdBox invisible" ref={thirdTraitThirdBox}></div>
            </div>
        </section>
    )
}

export default Palmares
