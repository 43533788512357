import { icon, library } from '@fortawesome/fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from 'axios'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingPage from '../../../components/global/LoadingPage'
import config from '../../../config/config'
import UserContext from '../../../contexts/UserContext'
import { catchErr } from '../../../error/catchErr'
import { SuccesSwal } from '../../../swal/swal'

library.add([faPlus, faTrash])

const iconPlus = icon({
    prefix: 'fas',
    iconName: 'plus',
})

const iconTrash = icon({
    prefix: 'fas',
    iconName: 'trash',
})

const EventForm = () => {
    const { token } = useContext(UserContext)
    const { id } = useParams()
    const navigate = useNavigate()

    const [title, setTitle] = useState('')
    const [scores, setScores] = useState([])
    const [date, setDate] = useState('')
    const [time, setTime] = useState('')

    const [games, setGames] = useState([])
    const [game, setGame] = useState('0')
    const [teamType, setTeamType] = useState([])
    const [teams, setTeams] = useState([])
    const [friendTeams, setFriendTeams] = useState([])

    const [opposingTeams, setOpposingTeams] = useState([])

    const [isEventLoading, setIsEventLoading] = useState(!!id)
    const [isOpposingTeamsLoading, setIsOpposingTeamsLoading] = useState(true)
    const [isTeamsLoading, setIsTeamsLoading] = useState(true)
    const [isGamesLoading, setIsGamesLoading] = useState(true)

    useEffect(() => {
        if (isEventLoading) {
            Axios.get(`${config.backendApi}/api/get-one/events/${id}`, config.apiHeader())
                .then(({ data }) => {
                    data.opposingTeams = data.opposingTeams.map((team) => ({ ...team, teamType: 'ext' }))
                    data.teams = data.teams.map((team) => ({ ...team, teamType: 'int' }))

                    const teams = data.teams.concat(data.opposingTeams)

                    teams.forEach((team, index) => {
                        teamType[index] = team.teamType
                    })

                    setTitle(data.title)
                    setGame(data.game.id)
                    setTeams(teams)
                    setDate(moment(data.date).format('YYYY-MM-DD'))
                    setTime(moment(moment(data.date).subtract(2, 'hour')).format('HH:mm'))
                    setTeamType(teamType)
                    setScores(data.scores)
                    setIsEventLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [id, isEventLoading, teamType])

    useEffect(() => {
        if (isGamesLoading && games.length === 0) {
            Axios.get(`${config.backendApi}/api/get-all/games`, config.apiHeader())
                .then(({ data }) => {
                    setGames(data['hydra:member'])
                    setIsGamesLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [games.length, isGamesLoading])

    useEffect(() => {
        if (isTeamsLoading && teams.length === 0) {
            Axios.get(`${config.backendApi}/api/get-all/teams`, config.apiHeader())
                .then(({ data }) => {
                    setFriendTeams(data['hydra:member'])
                    setIsTeamsLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [isTeamsLoading, teams.length])

    useEffect(() => {
        if (isOpposingTeamsLoading && opposingTeams.length === 0) {
            Axios.get(`${config.backendApi}/api/get-all/opposing-teams`, config.apiHeader())
                .then(({ data }) => {
                    setOpposingTeams(data['hydra:member'])
                    setIsOpposingTeamsLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [isOpposingTeamsLoading, opposingTeams.length])

    if (isEventLoading || isGamesLoading || isTeamsLoading || isOpposingTeamsLoading) return <LoadingPage />

    const handleSubmit = () => {
        const data = { title, game, teams, scores, date: date + 'T' + time }

        if (id) {
            Axios.patch(`${config.backendApi}/api/events/${id}`, data, config.apiHeader(token))
                .then(() => {
                    SuccesSwal(`L'événement a bien été modifié.`)
                    navigate('/admin/evenement')
                })
                .catch((err) => catchErr(err.response))
        } else {
            Axios.post(`${config.backendApi}/api/events`, data, config.apiHeader(token))
                .then(() => {
                    SuccesSwal(`L'événement ${title} a bien été créé.`)
                    navigate('/admin/evenement')
                })
                .catch((err) => catchErr(err.response))
        }
    }

    const addInput = () => {
        scores.push('n/a')
        teams.push('0')
        teamType.push('0')

        setScores([...scores])
        setTeams([...teams])
        setTeamType([...teamType])
    }

    const removeInput = (index) => {
        teamType.splice(index, 1)
        scores.splice(index, 1)
        teams.splice(index, 1)

        setScores([...scores])
        setTeams([...teams])
        setTeamType([...teamType])
    }

    return (
        <div className="content">
            <h1 className="col pageTitle">{id ? 'Modifier' : 'Créer'} un événement</h1>
            <form>
                <div className="mb-3 col">
                    <label htmlFor="title" className="form-label">
                        Titre de l'événement
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Le nom de l'événement"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        required
                    />
                </div>
                <div className="mb-3 col">
                    <label htmlFor="date" className="form-label">
                        Date de l'événement
                    </label>
                    <input
                        type="date"
                        className="form-control"
                        id="date"
                        name="date"
                        onChange={(e) => setDate(e.target.value)}
                        value={date}
                        required
                    />
                </div>
                <div className="mb-3 col">
                    <label htmlFor="time" className="form-label">
                        Heure de l'événement
                    </label>
                    <input
                        type="time"
                        className="form-control"
                        id="time"
                        name="time"
                        onChange={(e) => setTime(e.target.value)}
                        value={time}
                        required
                    />
                </div>
                <div className="mb-3 col">
                    <label htmlFor="game" className="form-label">
                        Jeu
                    </label>
                    <select
                        className="form-control form-select form-select-lg"
                        value={game}
                        onChange={(e) => setGame(e.target.value)}
                        required
                    >
                        <option value="0">Choisissez un jeu</option>
                        {games.map((game) => (
                            <option key={game.id} value={game.id}>
                                {game.gameName}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-3 col inputWithButton">
                    <div>
                        {teams.map((teamData, index) => (
                            <div key={`team${index}`} className="inputWithButton-inputAndButton">
                                <div className="mr-3">
                                    <label htmlFor="team" className="form-label">
                                        Équipes
                                    </label>
                                    <div className="teamSelectwrapper">
                                        <select
                                            className="teamTypeSelect form-control form-select form-select-lg"
                                            name="teamType"
                                            value={teamType[index]}
                                            onChange={(e) => {
                                                teamType[index] = e.target.value
                                                teams[index] = '0'
                                                setTeamType([...teamType])
                                                setTeams([...teams])
                                            }}
                                        >
                                            <option value="0">Choisissez le type d'équipe</option>
                                            <option value="int">Interne</option>
                                            <option value="ext">Externe</option>
                                        </select>
                                        {teamType[index] &&
                                            teamType[index] !== '0' &&
                                            (teamType[index] === 'int' ? (
                                                <select
                                                    className="teamSelect form-control form-select form-select-lg"
                                                    value={teamData.id}
                                                    onChange={(e) => {
                                                        let res = null

                                                        friendTeams.forEach((team) => {
                                                            if (team.id === +e.target.value) {
                                                                team.teamType = 'int'
                                                                res = team
                                                            }
                                                        })

                                                        if (res !== null) {
                                                            teams[index] = res
                                                        }

                                                        setTeams([...teams])
                                                    }}
                                                    required
                                                >
                                                    <option value="0">Choisissez une équipe</option>

                                                    {friendTeams.map((team) => (
                                                        <option key={`team${team.id}`} value={team.id}>
                                                            {team.teamName}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <select
                                                    className="teamSelect form-control form-select form-select-lg"
                                                    name="teams"
                                                    value={teamData.id}
                                                    onChange={(e) => {
                                                        let res = null

                                                        opposingTeams.forEach((team) => {
                                                            if (team.id === +e.target.value) {
                                                                team.teamType = 'ext'
                                                                res = team
                                                            }
                                                        })

                                                        if (res !== null) {
                                                            teams[index] = res
                                                        }
                                                    }}
                                                    required
                                                >
                                                    <option value="0">Choisissez une équipe</option>
                                                    {opposingTeams.map((team) => (
                                                        <option key={team.id} value={team.id}>
                                                            {team.teamName}
                                                        </option>
                                                    ))}
                                                </select>
                                            ))}
                                    </div>
                                </div>
                                {teamType[index] && teamType[index] !== '0' && (
                                    <div className="scores-container">
                                        <label htmlFor="scores" className="form-label">
                                            Score
                                        </label>
                                        <input
                                            key={index}
                                            type="text"
                                            className="form-control number score-input"
                                            onChange={(e) => {
                                                scores[index] = e.target.value
                                                setScores([...scores])
                                            }}
                                            value={scores[index]}
                                            required
                                        />
                                    </div>
                                )}
                                {teamType[index] && teamType[index] !== '0' && (
                                    <div className="inputWithButton-removeBtn">
                                        <div key={`team${index}`} className="inputWithButton-removeBtn-btn teams">
                                            <FontAwesomeIcon icon={iconTrash} onClick={() => removeInput(index)} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                        <div className="inputWithButton-inputAndButton-button" onClick={addInput}>
                            <FontAwesomeIcon icon={iconPlus} />
                        </div>
                    </div>
                </div>
                <div className="col">
                    <button className="btn btn-primary" type="button" onClick={handleSubmit}>
                        {id ? 'Modifier' : 'Créer'}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default EventForm
