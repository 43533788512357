//-------------------------------------------
//Collection 2020
//-------------------------------------------
//Import du Maillot
import maillotDvgFront from '../assets/images/shop/2020_maillot_front_light.png'
import maillotDvgBack from '../assets/images/shop/2020_maillot_back_light.png'
//Import du Sweat classique
import sweatDvg from '../assets/images/shop/2020_hoodie_front_light.png'
import sweatDvgBack from '../assets/images/shop/2020_hoodie_back_light.png'
//Import du T-Shirt classique
import tShirt from '../assets/images/shop/2020_TShirt_front_light.png'
//Import tapis de souris
import tapisSouris from '../assets/images/shop/2020_tapis_front_light.png'
//Import des détails
import detailsMaillot from './detailsMaillot.js'
import detailsSweat from './detailsSweat.js'
import detailsTDS from './detailsTDS.js'
import detailsTshirt from './detailsTshirt.js'
import detailsMenpoTShirt from './detailsMenpoTShirt.js'
import detailsMaillot2023 from './detailsMaillot2023'
import detailsYinYangTshirt from './detailsYinYangTshirt.js'
import detailsKanjiTshirt from './detailsKanjiTshirt'
import detailsHoodieYinYang from './detailsHoodieYinYang'
import detailsHoodieMenpo from './detailsHoodieMenpo'
import detailsHoodieSnake from './detailsHoodieSnake'

//-------------------------------------------
//Collection 2023
//-------------------------------------------
//Import du maillot 2023
import maillotFront2023 from '../assets/images/shop/2023_maillot_front_light.png'
import maillotBack2023 from '../assets/images/shop/2023_maillot_back_light.png'
//Import du T-Shirt Menpo 2023
import tShirtMenpoFront2023 from '../assets/images/shop/shopTShirtMenpoLight.jpg'
import tShirtMenpoFront2023_2 from '../assets/images/shop/2023_TShirt_front_Menpo_light.png'
import tShirtBack2023 from '../assets/images/shop/2023_TShirt_back_global_light.png'
//Import du T-Shirt Yin Yang 2023
import tShirtYinYangFront2023 from '../assets/images/shop/shopTShirtYinYangLight.jpg'
import tShirtYinYangFront2023_2 from '../assets/images/shop/2023_TShirt_front_YinYang_light.png'
//Import du T-Shirt Kanji 2023
import tShirtKanjiFront2023 from '../assets/images/shop/shopTShirtKanjiLight.jpg'
import tShirtKanjiFront2023_2 from '../assets/images/shop/2023_TShirt_front_Kanji_light.png'
//Import du Hoodie Yin Yang 2023
import hoodieYinYangFront2023 from '../assets/images/shop/shopHoodieYinYangLight.jpg'
import hoodieYinYangFront2023_2 from '../assets/images/shop/2023_hoodie_front_YinYang_light.png'
import hoodieYinYangBack2023 from '../assets/images/shop/2023_hoodie_back_YinYang_light.png'
//Import du Hoodie Snake 2023
import hoodieSnakeFront2023 from '../assets/images/shop/shopHoodieSnakeLight.jpg'
import hoodieSnakeFront2023_2 from '../assets/images/shop/2023_hoodie_front_Snake_light.png'
import hoodieSnakeBack2023 from '../assets/images/shop/2023_hoodie_back_Snake_light.png'
//Import du Hoodie Menpo 2023
import hoodieMenpoFront2023 from '../assets/images/shop/shopHoodieMenpoLight.jpg'
import hoodieMenpoFront2023_2 from '../assets/images/shop/2023_hoodie_front_Menpo_light.png'
import hoodieMenpoBack2023 from '../assets/images/shop/2023_hoodie_back_Menpo_light.png'

export const shoppingList = [
    {
        id: 'maillotDvg',
        itemName: 'MAILLOT 2020',
        img: maillotDvgFront,
        imgBack: maillotDvgBack,
        price: '39.90',
        desc: detailsMaillot,
        adress: 'https://eliminate.fr/produit/team-divergentes-jersey-2020/',
        new: false
    },
    {
        id: 'sweatDvg',
        itemName: 'HOODIE - TEAM DIVERGENTES',
        img: sweatDvg,
        imgBack: sweatDvgBack,
        price: '35.00',
        desc: detailsSweat,
        adress: 'https://eliminate.fr/produit/team-divergentes-hoodie/',
        new: false
    },
    {
        id: 'T-shirtDvg',
        itemName: 'T-SHIRT - TEAM DIVERGENTES',
        img: tShirt,
        imgBack: tShirtBack2023,
        price: '20.90',
        desc: detailsTshirt,
        adress: 'https://eliminate.fr/produit/team-divergentes-t-shirt/',
        new: false
    },
    {
        id: 'tapisSourisDvg',
        itemName: 'TAPIS DE SOURIS - TEAM DIVERGENTES',
        img: tapisSouris,
        imgBack: null,
        price: '17.50',
        desc: detailsTDS,
        adress: 'https://eliminate.fr/produit/team-divergentes-tapis-m/',
        new: false
    },
    {
        id: 'maillotDvg_2023',
        itemName: 'MAILLOT 2023',
        img: maillotFront2023,
        imgBack: maillotBack2023,
        price: '39.90',
        desc: detailsMaillot2023,
        adress: 'https://eliminate.fr/produit/team-divergentes-jersey-2023/',
        new: true,
        vip: true
    },
    {
        id: 'tShirtMenpo_2023',
        itemName: 'T-SHIRT MENPŌ',
        img: tShirtMenpoFront2023,
        imgFront: tShirtMenpoFront2023_2,
        imgBack: tShirtBack2023,
        price: '21.99',
        desc: detailsMenpoTShirt,
        adress: 'https://eliminate.fr/produit/team-divergentes-t-shirt-menpo-2022/',
        new: true,
        vip: false
    },
    {
        id: 'tShirtYinYang_2023',
        itemName: 'T-SHIRT YIN YANG',
        img: tShirtYinYangFront2023,
        imgFront: tShirtYinYangFront2023_2,
        imgBack: tShirtBack2023,
        price: '21.99',
        desc: detailsYinYangTshirt,
        adress: 'https://eliminate.fr/produit/team-divergentes-t-shirt-yin-yang-2022/',
        new: true,
        vip: false
    },
    {
        id: 'tShirtKanji_2023',
        itemName: 'T-SHIRT KANJI',
        img: tShirtKanjiFront2023,
        imgFront: tShirtKanjiFront2023_2,
        imgBack: tShirtBack2023,
        price: '21.99',
        desc: detailsKanjiTshirt,
        adress: 'https://eliminate.fr/produit/team-divergentes-t-shirt-kanji-2022/',
        new: true,
        vip: false
    },
    {
        id: 'hoodieYinYang_2023',
        itemName: 'HOODIE YIN YANG',
        img: hoodieYinYangFront2023,
        imgFront: hoodieYinYangFront2023_2,
        imgBack: hoodieYinYangBack2023,
        price: '42.50',
        desc: detailsHoodieYinYang,
        adress: 'https://eliminate.fr/produit/team-divergentes-hoodie-yin-yang-2022/',
        new: true,
        vip: false
    },
    {
        id: 'hoodieSnake_2023',
        itemName: 'HOODIE SNAKE',
        img: hoodieSnakeFront2023,
        imgFront: hoodieSnakeFront2023_2,
        imgBack: hoodieSnakeBack2023,
        price: '42.50',
        desc: detailsHoodieSnake,
        adress: 'https://eliminate.fr/produit/team-divergentes-hoodie-snake-2022/',
        new: true,
        vip: false
    },
    {
        id: 'hoodieMenpo_2023',
        itemName: 'HOODIE MENPŌ',
        img: hoodieMenpoFront2023,
        imgFront: hoodieMenpoFront2023_2,
        imgBack: hoodieMenpoBack2023,
        price: '42.50',
        desc: detailsHoodieMenpo,
        adress: 'https://eliminate.fr/produit/team-divergentes-hoodie-menpo-2022/',
        new: true,
        vip: false
    }
]
