import React, { useEffect, useState } from 'react'
import arrowLogo from '../assets/images/shop/Arrow_R3.png'
import sponsorLMN8 from '../assets/images/sponsor/LMN8.svg'
import sponsorPulsar from '../assets/images/sponsor/pulsar.svg'
import '../assets/scss/pages/_shop.scss'
import { shoppingList } from '../datas/shoppingList'
import Details from './Details'
//import societeGeneral from '../assets/images/sponsor/sg.svg'
import logoTD from '../assets/images/logo/logoTD.svg'
import collection2023 from '../assets/images/shop/background_collection_2023.svg'

const Shop = () => {
    const [visible, setVisible] = useState(false)
    const [detailState, setDetailState] = useState(null)
    const [detailBackState, setDetailBackState] = useState(null)
    const [detailAddress, setDetailAddress] = useState(null)
    const [detailPrice, setDetailPrice] = useState(null)
    const [detailDesc, setDetailDesc] = useState(null)
    const [detailName, setDetailName] = useState(null)

    const updateStates = (imgElement, imgBackElement, imgAddress, detailPrice, detailDesc, detailName) => {
        setVisible(true)
        setDetailState(imgElement)
        setDetailBackState(imgBackElement)
        setDetailAddress(imgAddress)
        setDetailPrice(detailPrice)
        setDetailDesc(detailDesc)
        setDetailName(detailName)
    }

    useEffect(() => {
        /*
        const banner = document.querySelector('.fourBanner');
        const bannerItems = document.querySelectorAll('.fourBanner span');
        let bannerAnimation = false;
        window.onscroll = function() {
            let rect = banner.getBoundingClientRect();
            if ((rect.top>= 0) && (rect.bottom <= window.innerHeight + (banner.offsetHeight / 2))) {
                if (bannerAnimation == false) {
                    bannerAnimation = true;
                    for (let i = 0; i < bannerItems.length; i++) {
                        bannerItems[i].classList.add('animation')
                    }
                }
            }
        }
        */
        const articles = document.querySelectorAll('.buyItems_footerArrow')

        articles.forEach((article) => {
            article.addEventListener('click', () => {
                article.closest('.buyItem').classList.toggle('active')
            })
        })
    })

    return (
        <>
            {/* <GlobalBanner title="Notre Boutique"> */}
            {/* <p className="text-center mainContainer"> */}
            {/* Nous possèdons effectivement une{' '} */}
            {/* <strong> */}
            {/* <a href="https://eliminate.fr/partner/teamdivergentes/">boutique</a> */}
            {/* </strong> */}
            {/* . En partenariat avec{' '} */}
            {/* <a href="https://twitter.com/eliminate_fr"> */}
            {/* <strong>@Eliminate</strong> */}
            {/* </a>{' '} */}
            {/* nous vous proposons différents produit a l'effigie de notre structure.{' '} */}
            {/* <strong>Un maillot esport, tee_shirt, sweat, et très bientôt des tapis de souris</strong>. Cette */}
            {/* boutique nous permet de financer les évenements proposés par la TeamDivergentes mais aussi de la */}
            {/* faire grandir. */}
            {/* </p> */}
            {/* </GlobalBanner> */}
            <Details
                visible={visible}
                setVisible={setVisible}
                detailState={detailState}
                detailBackState={detailBackState}
                detailAdress={detailAddress}
                detailPrice={detailPrice}
                detailDesc={detailDesc}
                detailName={detailName}
            />
            <div className="bannerImg">
                <div className="firstBannerImg"></div>
            </div>
            {/*
            <div className="bannerImg fourBanner">
                <span className="bannerImgArticlePresentation animation"></span>
                <span className="bannerImgArticlePresentation animation"></span>
                <span className="bannerImgArticlePresentation animation"></span>
                <span className="bannerImgArticlePresentation animation"></span>
                <span className="bannerImgArticlePresentation animation"></span>
                <span className="bannerImgArticlePresentation animation"></span>
            </div>
            */}
            <div className="sponsor_container sponsorToLeft">
                <div className="sponsorTrackContainer">
                    <div className="sponsorTrack">
                        <div className="sponsorContainerItem">
                            <a
                                href="https://www.behance.net/Pulsarcorp"
                                className="sponsorItemlink"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img className="sponsorItem" src={sponsorPulsar} alt="logo sponsor Pulsar" />
                            </a>
                            <div className="sponsorSeparator">X</div>
                            <a
                                href="https://eliminate.fr/"
                                className="sponsorItemlink"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img className="sponsorItem" src={sponsorLMN8} alt="logo sponsor LMN8" />
                            </a>
                            <div className="sponsorSeparator">X</div>
                        </div>
                        <div className="sponsorContainerItem">
                            <a
                                href="https://www.behance.net/Pulsarcorp"
                                className="sponsorItemlink"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img className="sponsorItem" src={sponsorPulsar} alt="logo sponsor Pulsar" />
                            </a>
                            <div className="sponsorSeparator">X</div>
                            <a
                                href="https://eliminate.fr/"
                                className="sponsorItemlink"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img className="sponsorItem" src={sponsorLMN8} alt="logo sponsor LMN8" />
                            </a>
                            <div className="sponsorSeparator">X</div>
                        </div>
                        <div className="sponsorContainerItem">
                            <a
                                href="https://www.behance.net/Pulsarcorp"
                                className="sponsorItemlink"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img className="sponsorItem" src={sponsorPulsar} alt="logo sponsor Pulsar" />
                            </a>
                            <div className="sponsorSeparator">X</div>
                            <a
                                href="https://eliminate.fr/"
                                className="sponsorItemlink"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img className="sponsorItem" src={sponsorLMN8} alt="logo sponsor LMN8" />
                            </a>
                            <div className="sponsorSeparator">X</div>
                        </div>
                        <div className="sponsorContainerItem">
                            <a
                                href="https://www.behance.net/Pulsarcorp"
                                className="sponsorItemlink"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img className="sponsorItem" src={sponsorPulsar} alt="logo sponsor Pulsar" />
                            </a>
                            <div className="sponsorSeparator">X</div>
                            <a
                                href="https://eliminate.fr/"
                                className="sponsorItemlink"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img className="sponsorItem" src={sponsorLMN8} alt="logo sponsor LMN8" />
                            </a>
                            <div className="sponsorSeparator">X</div>
                        </div>
                        <div className="sponsorContainerItem">
                            <a
                                href="https://www.behance.net/Pulsarcorp"
                                className="sponsorItemlink"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img className="sponsorItem" src={sponsorPulsar} alt="logo sponsor Pulsar" />
                            </a>
                            <div className="sponsorSeparator">X</div>
                            <a
                                href="https://eliminate.fr/"
                                className="sponsorItemlink"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img className="sponsorItem" src={sponsorLMN8} alt="logo sponsor LMN8" />
                            </a>
                            <div className="sponsorSeparator">X</div>
                        </div>
                        <div className="sponsorContainerItem">
                            <a
                                href="https://www.behance.net/Pulsarcorp"
                                className="sponsorItemlink"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img className="sponsorItem" src={sponsorPulsar} alt="logo sponsor Pulsar" />
                            </a>
                            <div className="sponsorSeparator">X</div>
                            <a
                                href="https://eliminate.fr/"
                                className="sponsorItemlink"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img className="sponsorItem" src={sponsorLMN8} alt="logo sponsor LMN8" />
                            </a>
                            <div className="sponsorSeparator">X</div>
                        </div>
                        <div className="sponsorContainerItem">
                            <a
                                href="https://www.behance.net/Pulsarcorp"
                                className="sponsorItemlink"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img className="sponsorItem" src={sponsorPulsar} alt="logo sponsor Pulsar" />
                            </a>
                            <div className="sponsorSeparator">X</div>
                            <a
                                href="https://eliminate.fr/"
                                className="sponsorItemlink"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img className="sponsorItem" src={sponsorLMN8} alt="logo sponsor LMN8" />
                            </a>
                            <div className="sponsorSeparator">X</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bannerImg secondBannerImg">
                <div className="containerBtnBanner">
                    <a
                        href="https://eliminate.fr/produit/team-divergentes-jersey-2023/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Acheter
                    </a>
                    {shoppingList.map((shoppingItem, index) =>
                        shoppingItem.new === true && shoppingItem.vip == true ? (
                            <a
                                onClick={() =>
                                    updateStates(
                                        shoppingItem.img,
                                        shoppingItem.imgBack,
                                        shoppingItem.adress,
                                        shoppingItem.price,
                                        shoppingItem.desc,
                                        shoppingItem.itemName
                                    )
                                }
                            >
                                Détails
                            </a>
                        ) : null
                    )}
                </div>
            </div>
            {/*<img className="img_thirdBannerShop" src={imageNewCollection2023}/>*/}
            <div className="sponsor_container sponsorToRight">
                <div className="sponsorTrackContainer">
                    <div className="sponsorTrack">
                        <div className="sponsorContainerItem">
                            <div className="sponsorSeparator sponsorSeparatorTxt">nouvelle collection</div>
                            <img className="sponsorItem" src={logoTD} alt="logo TeamDivergentes" />
                            <div className="sponsorSeparator sponsorSeparatorTxt">nouvelle collection</div>
                            <img className="sponsorItem" src={logoTD} alt="logo TeamDivergentes" />
                        </div>
                        <div className="sponsorContainerItem">
                            <div className="sponsorSeparator sponsorSeparatorTxt">nouvelle collection</div>
                            <img className="sponsorItem" src={logoTD} alt="logo TeamDivergentes" />
                            <div className="sponsorSeparator sponsorSeparatorTxt">nouvelle collection</div>
                            <img className="sponsorItem" src={logoTD} alt="logo TeamDivergentes" />
                        </div>
                        <div className="sponsorContainerItem">
                            <div className="sponsorSeparator sponsorSeparatorTxt">nouvelle collection</div>
                            <img className="sponsorItem" src={logoTD} alt="logo TeamDivergentes" />
                            <div className="sponsorSeparator sponsorSeparatorTxt">nouvelle collection</div>
                            <img className="sponsorItem" src={logoTD} alt="logo TeamDivergentes" />
                        </div>
                        <div className="sponsorContainerItem">
                            <div className="sponsorSeparator sponsorSeparatorTxt">nouvelle collection</div>
                            <img className="sponsorItem" src={logoTD} alt="logo TeamDivergentes" />
                            <div className="sponsorSeparator sponsorSeparatorTxt">nouvelle collection</div>
                            <img className="sponsorItem" src={logoTD} alt="logo TeamDivergentes" />
                        </div>
                        <div className="sponsorContainerItem">
                            <div className="sponsorSeparator sponsorSeparatorTxt">nouvelle collection</div>
                            <img className="sponsorItem" src={logoTD} alt="logo TeamDivergentes" />
                            <div className="sponsorSeparator sponsorSeparatorTxt">nouvelle collection</div>
                            <img className="sponsorItem" src={logoTD} alt="logo TeamDivergentes" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bannerImg thirdBannerImg"></div>
            <div className="containerNewCollection_2023">
                <img src={collection2023} alt="image nouvelle collection 2023" />
            </div>
            <div className="buyItemsContainer">
                {shoppingList.map((shoppingItem, index) =>
                    shoppingItem.new === true && shoppingItem.vip == false ? (
                        <article className="buyItem" key={shoppingItem.id}>
                            <div className="buyItems_Header">
                                <img src={shoppingItem.img} alt={shoppingItem.itemName} />
                            </div>
                            <div className="buyItems_footer">
                                <div className="buyItems_footerArrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                                    </svg>
                                </div>
                                <div className="buyItems_footerName">{shoppingItem.itemName}</div>
                                <div className="buyItems_footerContainerBtn">
                                    <button
                                        className="buyItems_footerBtn"
                                        onClick={() =>
                                            updateStates(
                                                shoppingItem.imgFront,
                                                shoppingItem.imgBack,
                                                shoppingItem.adress,
                                                shoppingItem.price,
                                                shoppingItem.desc,
                                                shoppingItem.itemName
                                            )
                                        }
                                    >
                                        <i className="fa-solid fa-eye"></i> Détails
                                    </button>
                                    <a
                                        className="buyItems_footerBtn"
                                        href={shoppingItem.adress}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <i className="fa-solid fa-cart-shopping"></i> Acheter
                                    </a>
                                </div>
                            </div>
                        </article>
                    ) : null
                )}
            </div>
            <div className="sponsor_container">ancienne collection 2020 - 2022</div>
            <div className="buyItems oldItems">
                <ul className="list">
                    {shoppingList.map((shoppingItem, index) =>
                        shoppingItem.new === false ? (
                            <li key={shoppingItem.id}>
                                <div className="contentBorder"></div>
                                <div className="content-shop">
                                    <div className="itemsFirst">
                                        {shoppingItem.imgBack == null ? (
                                            <img
                                                className="itemsHover"
                                                src={shoppingItem.img}
                                                alt={shoppingItem.itemName}
                                            />
                                        ) : (
                                            <img
                                                className="itemsHover"
                                                src={shoppingItem.imgBack}
                                                alt={shoppingItem.itemName}
                                            />
                                        )}
                                        <img className="items" src={shoppingItem.img} alt={shoppingItem.itemName} />
                                    </div>
                                    <p>
                                        <strong>{shoppingItem.itemName}</strong>
                                    </p>
                                    <div className={index % 2 ? 'optionsItemsRight' : 'optionsItemsLeft'}>
                                        <div className="containerLogoBottomItems">
                                            <img src={arrowLogo} alt="arrowLogo" />
                                        </div>
                                        <div className={index % 2 ? 'actionItemsRight' : 'actionItemsLeft'}>
                                            <button
                                                onClick={() =>
                                                    updateStates(
                                                        shoppingItem.img,
                                                        shoppingItem.imgBack,
                                                        shoppingItem.adress,
                                                        shoppingItem.price,
                                                        shoppingItem.desc,
                                                        shoppingItem.itemName
                                                    )
                                                }
                                                className="linkDetails"
                                            >
                                                <i className="fa-solid fa-eye"></i> DETAILS
                                            </button>
                                            <a
                                                className="linkAdd"
                                                href={shoppingItem.adress}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="fa-solid fa-cart-shopping"></i> ACHETER
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ) : null
                    )}
                </ul>
            </div>
            {/*<iframe
                id="site_lmn8"
                src="https://eliminate.fr/partner/teamdivergentes/"
                title="shoplmn8"
                width="90%"
                height="700"
            />*/}
        </>
    )
}

export default Shop
