import styled from '@emotion/styled'

const MainContainer = styled.div`
    max-width: 1600px;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 100px;
`

export default MainContainer
