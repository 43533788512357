import '../assets/scss/pages/_details.scss'

export default function MaillotDesc2023() {
    return (
        <div className="elementContent">
            <p className="description_modalDetail">
                Notre nouveau maillot sublimé est conçu avec de la maille 100% polyester européen <span>(grammage de 160 gr/m²)</span>.
            </p>
            <p className="description_modalDetail">
                Un tissu doux et <span>anti-transpirant qui apporte confort et légèreté</span> en toute circonstance.
            </p>
        </div>
    )
}