import { ACTION_TYPE_AUTH } from '../action'

const INITSTATE = {
    authenticated: false,
    role: '',
    token: '',
}

const authReducer = (state = INITSTATE, action) => {
    switch (action.type) {
        case ACTION_TYPE_AUTH.LOGIN:
            return {
                ...state,
                authenticated: true,
                role: action.role,
                token: action.token,
            }
        case ACTION_TYPE_AUTH.LOGOUT:
            return {
                ...state,
                authenticated: false,
                role: '',
                token: '',
            }
        default:
            break
    }
    return state
}

export default authReducer
