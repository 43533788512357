import Axios from 'axios'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LoadingPage from '../../../components/global/LoadingPage'
import Table from '../../../components/global/table/Table'
import config from '../../../config/config'
import UserContext from '../../../contexts/UserContext'
import { catchErr } from '../../../error/catchErr'
import { SuccesSwal } from '../../../swal/swal'

const addTeamsAndScoresNumberInHeader = (data, maxTeamNumber) => {
    const resTeams = []
    const resScores = []
    for (let i = 0; i < maxTeamNumber; i++) {
        resTeams.push(`Team ${i + 1}`)
        resScores.push(`Score ${i + 1}`)
    }

    return { teams: resTeams, scores: resScores }
}

const Event = () => {
    const { token } = useContext(UserContext)

    const [isLoading, setIsLoading] = useState(true)
    const [eventListData, setEventListData] = useState([])
    const [tableHeader, setTableHeader] = useState(['#', "Titre de l'evenement", 'Jeu'])

    useEffect(() => {
        if (isLoading && eventListData.length === 0) {
            Axios.get(`${config.backendApi}/api/get-all/events?order[id]=asc`, config.apiHeader())
                .then((res) => {
                    let maxTeamNumber = 0

                    res.data['hydra:member'].forEach((event) => {
                        maxTeamNumber =
                            event.teams.length + event.opposingTeams.length > maxTeamNumber
                                ? event.teams.length + event.opposingTeams.length
                                : maxTeamNumber
                    })

                    const maxTeamsAndScoresNumber = addTeamsAndScoresNumberInHeader(
                        res.data['hydra:member'],
                        maxTeamNumber
                    ) // return {teams , scores}

                    let newTableHeader = tableHeader.concat(maxTeamsAndScoresNumber.teams)
                    newTableHeader = newTableHeader.concat(maxTeamsAndScoresNumber.scores)
                    newTableHeader = newTableHeader.concat(['Date', '', ''])

                    const events = res.data['hydra:member'].map((event) => {
                        let eventData = [event.id, event.title, event.game.gameName]
                        let teamsList = event.teams.concat(event.opposingTeams)

                        for (let i = 0; i < maxTeamNumber; i++) {
                            if (teamsList[i] !== undefined) {
                                eventData.push(teamsList[i].teamName)
                            } else {
                                eventData.push('n/a')
                            }
                        }

                        for (let i = 0; i < maxTeamNumber; i++) {
                            if (event.scores[i] !== undefined) {
                                eventData.push(event.scores[i])
                            } else {
                                eventData.push('n/a')
                            }
                        }

                        eventData.push(moment(moment(event.date).subtract(2, 'hour')).format('DD/MM/YYYY HH:mm'))
                        const actionArray = [['Modifier', `/admin/evenement/modifier/${event.id}`], 'Supprimer']
                        eventData = eventData.concat(actionArray)

                        return eventData
                    })

                    setEventListData(events)
                    setTableHeader(newTableHeader)
                    setIsLoading(false)
                })
                .catch((err) => catchErr(err.response))
        }
    }, [eventListData.length, isLoading, tableHeader])

    if (isLoading) return <LoadingPage />

    const deleteById = (id) => {
        Axios.delete(`${config.backendApi}/api/events/${id}`, config.apiHeader(token))
            .then(() => {
                SuccesSwal('La suppression de cette événement est réussi ', 'refresh')
            })
            .catch((err) => catchErr(err.response))
    }

    return (
        <div className="content">
            <div className="action">
                <Link to="/admin/evenement/creer" className="btn btn-success">
                    Créer un événement
                </Link>
            </div>
            {eventListData.length > 0 && (
                <Table dataList={eventListData} tableHeader={tableHeader} deleteById={deleteById} />
            )}
        </div>
    )
}

export default Event
